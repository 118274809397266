import clsx from "clsx";
import { motion } from "framer-motion";
import Backdrop from "../../../../components/common/Backdrop";
import { dropIn } from "../../../../components/common/ModalContainer";
import BaseBtn from "../BaseBtn";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../../constants/navbar.constants";
import { useBirdBattle } from "../../../../store/useBirdBattle";

const CreatematchModal = ({
  type,
  title,
  desciption,
  handleClose,
  openJoinModal,
  retreat,
}: {
  type: string;
  title: string;
  desciption: string;
  handleClose: () => void;
  openJoinModal?: () => void;
  retreat?: () => void;
}) => {
  const navigate = useNavigate();
  const setPlayWithFrens = useBirdBattle(
    (state: any) => state.setPlayWithFrens,
  );
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);
  const updateUsers = useBirdBattle((state: any) => state.updateUsers);

  const handleCreateMatch = () => {
    updateMatch(null);
    updateUsers([]);
    // updateRoomType(item);
    // navigate(navPaths.FIND_MATCH);
    setPlayWithFrens(true);
    navigate(navPaths.DEPLOY_BIRD);
  };

  return (
    <Backdrop onClick={() => handleClose()} className="!bg-[#000000CC]">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFFFFF] border-[6px] border-[#39494b] rounded-[20px] py-[23px] px-5 flex flex-col items-center justify-center relative ",
          )}
        >
          <>
            <p className="text-[#021B00] text-[28px] font-baloo-paaji font-black capitalize">
              {title}
            </p>
            <p className="text-[#4C544C] mt-3 text-center">{desciption}</p>
          </>
          {type === "create" && (
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <BaseBtn
                onClick={() => {
                  handleClose();
                  handleCreateMatch();
                }}
              >
                Create
              </BaseBtn>
              <BaseBtn
                onClick={() => {
                  handleClose();
                  openJoinModal && openJoinModal();
                }}
              >
                Join
              </BaseBtn>
            </div>
          )}
          {type === "retreat" && (
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <button
                onClick={() => {
                  handleClose();
                }}
                className={clsx(
                  "text-[#0B8B00] text-[24px] w-full font-baloo-paaji font-bold uppercase py-[7px]",
                  "border-[3px] border-[#0B8B00] bg-white shadow-[0_3.5px_0.5px_#0B8B00] rounded-xl",
                  "active:translate-y-[3.5px] active:shadow-none",
                )}
              >
                No
              </button>
              <BaseBtn
                onClick={() => {
                  handleClose();
                  retreat && retreat();
                }}
              >
                Yes
              </BaseBtn>
            </div>
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default CreatematchModal;
