import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const MainBox = lazy(() => import("./Pages/MainBox"));
const UpgradeBox = lazy(() => import("./Pages/UpgradeBox"));
const OpenBox = lazy(() => import("./Pages/OpenBox"));

export const mysteryBoxRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.MYSTERY_BOX, Component: MainBox },
    { path: navPaths.UPGRADE_BOX, Component: UpgradeBox },
    { path: navPaths.OPEN_BOX, Component: OpenBox },
  ],
};
