import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { toast } from "react-toastify";
import useCreateAcount from "../../../components/Hooks/useCreateAcount";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import { useNewComerStore } from "../../../store/newComerStore";
import { sleep } from "../../../utils/time";

// eslint-disable-next-line no-empty-pattern
export const CalculatePoint = ({}: { setTab: any }) => {
  const { accountV2, isLoading, refetch: refreshAccount } = useAccountV2();
  const newComerData = useNewComerStore((state: any) => state.newComerData);
  const updateNewComerData = useNewComerStore(
    (state: any) => state.updateNewComerData,
  );

  const [loading, setLoading] = useState<boolean>(true);

  const { mutateAsync: registerAsync } = useCreateAcount();

  const handleRegister = () => {
    setLoading(true);
    registerAsync()
      .then(async (rs) => {
        if (rs?.data) {
          // setTab(2);
          handleContinueRegister();
        }
      })
      .catch((err: any) => {
        toast.error(err, { autoClose: 2000 });
      });
  };

  const handleContinueRegister = async () => {
    refreshAccount();
    // await claimFirstEggAsync();
    // tele.openTelegramLink("https://t.me/seedupdates");
  };

  useEffect(() => {
    updateNewComerData(accountV2?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountV2]);

  useEffect(() => {
    if (newComerData) {
      setLoading(false);
    }
  }, [newComerData]);

  const [luckyProgress, setLuckyProgress] = useState<boolean>(false);

  const checkWelcomeRewardProgess = async () => {
    await sleep(3000);
    setLuckyProgress(true);
  };

  const [telegramAgeProgress, setTelegramAgeProgress] =
    useState<boolean>(false);
  const checkTelegramAge = async () => {
    await sleep(6000);
    setTelegramAgeProgress(true);
  };

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const checkTelePremiumProgess = async () => {
    await sleep(9000);
    setCheckAll(true);
  };

  useEffect(() => {
    checkWelcomeRewardProgess();
    checkTelegramAge();
    checkTelePremiumProgess();
  }, []);

  const imageElement = new Image();
  imageElement.src = "/images/trees/main.png";

  const [imageLoad, setImageLoad] = useState<boolean>(false);

  if (loading) {
    return (
      <div className=" backdrop-blur-sm fixed inset-0 flex items-center justify-center z-50 bg-[#F2FFE0]">
        <CircularProgress style={{ color: "black" }} />
      </div>
    );
  }

  return (
    <div className="flex flex-col max-w-md mx-auto items-center bg-white min-h-screen h-screen relative">
      <div
        className="w-full relative overflow-hidden flex flex-col items-center justify-center"
        style={{
          height: isBrowser ? "55%" : imageLoad ? "auto" : "55%",
          maxHeight: !isBrowser ? "60%" : imageLoad ? "auto" : "65%",
        }}
      >
        <div className="-mb-[7%] mr-[5%]">
          <svg
            width="200"
            height="57"
            viewBox="0 0 200 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M187.426 41.3058C189.814 41.3058 191.651 40.6629 192.937 39.3771C194.223 38.0913 194.866 36.2544 194.866 33.8665V23.0957C194.866 21.9268 194.699 20.8831 194.365 19.9647C194.031 19.0462 193.538 18.2697 192.887 17.6352C192.252 16.9839 191.476 16.4913 190.557 16.1573C189.639 15.8234 188.595 15.6564 187.426 15.6564H169.166V41.3058H187.426ZM169.166 46.4407V10.5215H187.426C189.347 10.5215 191.075 10.8221 192.611 11.4232C194.164 12.0077 195.483 12.851 196.569 13.9531C197.671 15.0385 198.514 16.3577 199.099 17.9107C199.7 19.4637 200 21.192 200 23.0957V33.8665C200 35.7701 199.7 37.4985 199.099 39.0514C198.514 40.6044 197.671 41.932 196.569 43.0341C195.483 44.1195 194.164 44.9628 192.611 45.564C191.075 46.1484 189.347 46.4407 187.426 46.4407H169.166Z"
              fill="black"
            />
            <path
              d="M138.181 10.5215H164.357V15.6564H138.181V25.9262H161.276V31.0611H138.181V41.3058H164.357V46.4407H138.181V10.5215Z"
              fill="#63EF02"
            />
            <path
              d="M107.196 10.5215H133.372V15.6564H107.196V25.9262H130.291V31.0611H107.196V41.3058H133.372V46.4407H107.196V10.5215Z"
              fill="#63EF02"
            />
            <path
              d="M79.819 31.061C79.1176 31.061 78.4497 30.9275 77.8151 30.6603C77.1973 30.3764 76.6545 30.009 76.187 29.5582C75.7194 29.0906 75.352 28.5479 75.0849 27.93C74.8177 27.2955 74.6841 26.6275 74.6841 25.9262V15.6564C74.6841 14.955 74.8177 14.2954 75.0849 13.6776C75.352 13.043 75.7194 12.5003 76.187 12.0494C76.6545 11.5819 77.1973 11.2145 77.8151 10.9473C78.4497 10.6634 79.1176 10.5215 79.819 10.5215H102.387V15.6564H79.819V25.9262H97.2526C97.9539 25.9262 98.6135 26.0597 99.2314 26.3269C99.8659 26.5941 100.417 26.9615 100.885 27.4291C101.352 27.8966 101.719 28.4477 101.987 29.0822C102.254 29.7001 102.387 30.3597 102.387 31.061V41.3058C102.387 42.0071 102.254 42.6751 101.987 43.3097C101.719 43.9275 101.352 44.4702 100.885 44.9378C100.417 45.4054 99.8659 45.7727 99.2314 46.0399C98.6135 46.3071 97.9539 46.4407 97.2526 46.4407H74.6841V41.3058H97.2526V31.061H79.819Z"
              fill="black"
            />
            <path
              d="M30.4349 2L8.08121 15.2627M30.4349 2V4.59396M30.4349 2L48.5527 15.7877M8.08121 15.2627L2 22.5007M8.08121 15.2627L13.6591 23.3374M2 22.5007L3.1743 34.299M2 22.5007L4.60024 33.5459M3.1743 34.299L16.1993 47.045C16.3227 47.1658 16.4617 47.2694 16.6128 47.353L30.4349 55.0088M3.1743 34.299L4.60024 33.5459M30.4349 55.0088L41.7701 47.3883C41.9292 47.2813 42.0715 47.1512 42.1921 47.0022L52.7466 33.9643M30.4349 55.0088L18.9854 39.6961M30.4349 55.0088L42.5973 39.1104M52.7466 33.9643L54.2854 24.9507C54.3486 24.5806 54.3021 24.2001 54.1516 23.856L51.8564 18.6072C51.7251 18.307 51.5194 18.0454 51.2586 17.847L48.5527 15.7877M52.7466 33.9643L45.1975 23.8395M52.7466 33.9643L42.5973 39.1104M30.4349 4.59396L45.1975 23.8395M30.4349 4.59396L31.1059 29.2784M30.4349 4.59396L13.6591 23.3374M45.1975 23.8395L48.5527 15.7877M45.1975 23.8395L31.1059 29.2784M42.5973 39.1104L18.9854 39.6961M42.5973 39.1104L31.1059 29.2784M18.9854 39.6961L4.60024 33.5459M18.9854 39.6961L31.1059 29.2784M4.60024 33.5459L13.6591 23.3374M31.1059 29.2784L13.6591 23.3374"
              stroke="url(#paint0_linear_9185_885)"
              stroke-width="2.56554"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_9185_885"
                x1="30.3934"
                y1="2"
                x2="30.3934"
                y2="55.0088"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#46F503" />
                <stop offset="1" stop-color="#77EB02" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <img
          src={imageElement.src}
          onLoad={() => setImageLoad(true)}
          className={clsx("w-full h-[70%] object-contain mr-[2%]")}
          alt="welcome-banner"
        ></img>
        <div className="flex flex-col items-center justify-center gap-2 sm:gap-4 -mt-[5%]">
          <span className="text-lg font-medium text-[#0E1504] ">
            SEED is ready to blossom
          </span>
          <div className="bg-[#61A70029] rounded-[30px] h-[36px] text-[#4E8600] w-fit flex items-center justify-center px-6">
            <span className="text-[16px] font-[400]">
              Listing: <span className="font-[600]">November</span>
            </span>
          </div>
        </div>
      </div>

      <div className="z-10 w-full px-4 pb-4 flex flex-col items-center relative">
        <div
          className={clsx(
            "w-full flex flex-col gap-2 lsm:gap-4 text-[14px] font-semibold text-[#0E1504]",
            isBrowser ? "mt-[10px]" : "mt-[15px] lsm:mt-[30px]",
          )}
        >
          <div className="flex flex-col gap-[7px]">
            <div className="flex items-center justify-between">
              <span className="font-[400]">Welcome Rewards</span>
              {newComerData && newComerData?.newcomer_event_boost_duration && (
                <div
                  className={clsx(
                    "flex items-center justify-between gap-1 transition-all duration-300 ease-linear",
                    luckyProgress
                      ? "translate-x-0 opacity-100"
                      : "translate-x-10 opacity-0",
                  )}
                >
                  <span className="text-[18px] font-[500]">
                    {/* todo: ?x speed */}
                    {newComerData?.newcomer_event_boost_duration}h x4 Speed
                  </span>
                  <img
                    src="/images/startup-speed.png"
                    className="w-[18px] h-[18px]"
                    alt="startup"
                  ></img>
                </div>
              )}
            </div>
            <div className="relative h-[10px] rounded-2xl bg-[#D2DCC3]">
              <div
                id="welcome-reward"
                className="bg-[#61A700] h-[10px] rounded-2xl absolute inset-0 loadingStartUp"
              />
            </div>
          </div>

          {newComerData && newComerData?.age && (
            <div className="flex flex-col gap-[7px]">
              <div className="flex items-center justify-between">
                <span className="font-[400]">
                  Telegram Ages Bonus ({newComerData?.age} year ago)
                </span>
                <div
                  className={clsx(
                    "flex items-center justify-between gap-1 transition-all duration-300 ease-linear",
                    telegramAgeProgress
                      ? "translate-x-0 opacity-100"
                      : "translate-x-10 opacity-0",
                  )}
                >
                  <span className="text-[18px] font-[500]">
                    +{newComerData?.age}
                  </span>
                  <img
                    src="/images/icons/token_icon.png"
                    className="w-[22px] h-[22px]"
                    alt="seed"
                  ></img>
                </div>
              </div>
              <div className="relative h-[10px] rounded-2xl bg-[#D2DCC3]">
                <div
                  id="tele-premium"
                  className={clsx(
                    "bg-[#61A700] h-[10px] rounded-2xl absolute inset-0",
                    luckyProgress ? "loadingStartUp" : "w-0",
                  )}
                />
              </div>
            </div>
          )}

          {newComerData && newComerData?.bonus_premium > 0 && (
            <div className="flex flex-col gap-[7px]">
              <div className="flex items-center justify-between">
                <span className="font-[400]">Telegram Premium</span>
                <div
                  className={clsx(
                    "flex items-center justify-between gap-1 transition-all duration-300 ease-linear",
                    checkAll
                      ? "translate-x-0 opacity-100"
                      : "translate-x-10 opacity-0",
                  )}
                >
                  <span className="text-[18px] font-[500]">
                    +{newComerData?.bonus_premium / 10 ** 9}
                  </span>
                  <img
                    src="/images/icons/token_icon.png"
                    className="w-[22px] h-[22px]"
                    alt="startup"
                  ></img>
                </div>
              </div>
              <div className="relative h-[10px] rounded-2xl bg-[#D2DCC3]">
                <div
                  id="tele-premium"
                  className={clsx(
                    "bg-[#61A700] h-[10px] rounded-2xl absolute inset-0",
                    telegramAgeProgress ? "loadingStartUp" : "w-0",
                  )}
                />
              </div>
            </div>
          )}
          {/* {newComerData && !newComerData?.give_first_egg && (
            <div className="flex flex-col gap-[7px]">
              <div className="flex items-center justify-between">
                <span className="font-[400]">Claim first egg</span>
                <div
                  className={clsx(
                    "flex items-center justify-between gap-1 transition-all duration-300 ease-linear",
                    checkAll
                      ? "translate-x-0 opacity-100"
                      : "translate-x-10 opacity-0",
                  )}
                >
                  <span className="text-[18px] font-[500]">+1</span>
                  <img
                    src="/images/egg/basic.png"
                    className="w-[22px] h-[22px]"
                    alt="startup"
                  ></img>
                </div>
              </div>
              <div className="relative h-[10px] rounded-2xl bg-[#D2DCC3]">
                <div
                  id="tele-premium"
                  className={clsx(
                    "bg-[#61A700] h-[10px] rounded-2xl absolute inset-0",
                    luckyProgress ? "loadingStartUp" : "w-0",
                  )}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>

      <div>
        <LoadingButton
          loading={isLoading}
          onClick={() => handleRegister()}
          className="btn-hover fixed bottom-[20px] text-[16px] left-4 right-4 capitalize font-extrabold text-white py-[16px] rounded-[38px] bg-gradient-to-r from-[#61A700] to-[#61A700]"
        >
          {"Continue"}
        </LoadingButton>
      </div>
    </div>
  );
};
