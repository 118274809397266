import { a, useSpring, useSpringRef } from "@react-spring/web";
import clsx from "clsx";
import { useEffect } from "react";

const PlayerItem = ({ player }: { player?: any }) => {
  const playerRef = useSpringRef();
  const playerAnim = useSpring({
    ref: playerRef,
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    reset: true,
    config: { tension: 240, friction: 8 },
  });

  const playerReadyRef = useSpringRef();
  const playerReadyAnim = useSpring({
    ref: playerReadyRef,
    from: {
      opacity: player?.ready ? 0 : 1,
      scale: player?.ready ? 0 : 1,
    },
    to: {
      opacity: player?.ready ? 1 : 0,
      scale: player?.ready ? 1 : 0,
    },
    config: { tension: 180, friction: 15 },
  });

  useEffect(() => {
    if (player?.guest) {
      playerRef.start();
    }
  }, [playerRef, player?.guest]);

  useEffect(() => {
    playerReadyRef.start();
  }, [playerReadyRef, player?.ready]);

  return (
    <a.div
      style={player?.guest && playerAnim}
      className={clsx(
        "border-[3px] bprder-[#FFFFFF] rounded-xl bg-[#0000004D] py-3 lsm:py-[21px] px-4 w-full",
        "flex gap-4 items-center relative",
      )}
    >
      <div
        className={clsx(
          "size-[60px] rounded-[50%] border-[2px] border-[#FFFFFF33] overflow-hidden",
          "flex items-center justify-center",
        )}
      >
        {player && player?.username ? (
          <img
            src={`https://konsen-image-proxy.taskfi.io/proxy/${player?.username}`}
            alt=""
          ></img>
        ) : (
          <img src="/images/battle_bird/player-placeholder.png" alt=""></img>
        )}
      </div>
      <div className="flex-1">
        <p className="text-[22px] text-[#fff] text-baloo-border font-bold font-baloo-paaji line-clamp-1">
          {player && player?.username ? player?.username : "- - - - -"}
        </p>
        <div className="flex items-center justify-between font-medium text-[#fff]">
          <p>Won: {player?.win ? player?.win : "---"}</p>
          <p>|</p>
          <p>Lost: {player?.lose ? player?.lose : "---"}</p>
        </div>
      </div>
      {player?.guest && (
        <a.div
          style={playerReadyAnim}
          className="absolute right-2 top-2 text-[#fff] text-baloo-border font-bold font-baloo-paaji uppercase border-2 bg-[#68C022] rounded-lg px-3 pt-[2px]"
        >
          Ready
        </a.div>
      )}
    </a.div>
  );
};

export default PlayerItem;
