import { create } from "zustand";

interface LoadingImg {
  isLoaded: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
}

export const useCheckLoadImg = create<LoadingImg>((set) => ({
    isLoaded: false,
    setIsLoaded: (isLoaded: boolean) => set({ isLoaded: isLoaded }),
  }));
