import { create } from "zustand";

interface BirdBattleState {
  endgame: any;
  randomHitExclude: any[];
  yourTurn: any;
  roomType: any;
  room: any;
  match: any;
  currentState: any;
  updateEndgame: (endgame: any) => void;
  updateRandomHitExclude: (randomHitExclude: any) => void;
  updateYourTurn: (yourTurn: any) => void;
  updateRoomType: (roomType: string) => void;
  updateRoom: (room: any) => void;
  updateMatch: (match: any) => void;
  users: any[];
  updateUsers: (newUser: any) => void;
  lineup: any[];
  playWithFrens: boolean;
  joinRoomCode: string;
}

export const useBirdBattle = create<BirdBattleState>((set, get) => ({
  roomType: null,
  room: null,
  match: null,
  lineup: [],
  endgame: null,
  randomHitExclude: [],
  yourTurn: null,
  currentState: null,
  updateCurrentState: (currentState: any) =>
    set({ currentState: currentState }),
  updateYourTurn: (yourTurn: any) => set({ yourTurn: yourTurn }),
  updateRandomHitExclude: (randomHitExclude: any) =>
    set({ randomHitExclude: randomHitExclude }),
  updateRoomType: (roomType: string) => set({ roomType: roomType }),
  updateRoom: (room: any) => set({ room: room }),
  updateMatch: (match: any) => set({ match: match }),
  updateLineup: (lineup: any) => set({ lineup: lineup }),
  updateEndgame: (endgame: any) => set({ endgame: endgame }),

  users: [],
  updateUsers: (users: any) => set({ users: users }),

  playWithFrens: false,
  setPlayWithFrens: (playWithFrens: boolean) =>
    set({ playWithFrens: playWithFrens }),

  joinRoomCode: "",
  setJoinRoomCode: (code: string) => set({ joinRoomCode: code }),
}));
