type SubscriptionParams = {
  id: number;
  method: string;
  params?: string[];
};

class WS {
  private socket: WebSocket | null = null;
  private url: string;
  private subscriptionId: number | null = null;
  private onMessageCallback: ((message: any) => void) | null = null;
  private getInfoId: number | null = null;

  private reconnectInterval: number;
  private maxReconnectAttempts: number;
  private reconnectAttempts: number = 0;
  private reconnectTimeout: NodeJS.Timeout | null = null;
  private onReconnectCallback: (() => void) | null = null;
  private manualClose: boolean = false; // Track if the disconnection was manual
  private isReconnecting: boolean = false; // Track if currently reconnecting
  private hasReconnected: boolean = false; // Track if the WebSocket has reconnected after a disconnection

  constructor(
    url: string,
    reconnectInterval = 3000,
    maxReconnectAttempts = 20,
  ) {
    this.url = url;
    this.reconnectInterval = reconnectInterval;
    this.maxReconnectAttempts = maxReconnectAttempts;
    this.connect();
  }

  // Method to connect to the WebSocket server and return a promise that resolves when connected
  private connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socket = new WebSocket(this.url);

      this.socket.onopen = () => {
        // console.log("WebSocket connection established");
        this.reconnectAttempts = 0; // Reset the reconnect attempts on successful connection
        this.manualClose = false; // Reset manualClose on successful connection
        this.isReconnecting = false; // Reset isReconnecting on successful connection

        // Clear any ongoing reconnect timeout attempts
        if (this.reconnectTimeout) {
          clearTimeout(this.reconnectTimeout);
          this.reconnectTimeout = null;
        }

        // Only call the onReconnectCallback if this is a reconnection
        if (this.onReconnectCallback && this.hasReconnected) {
          this.onReconnectCallback();
          this.hasReconnected = false; // Reset the flag after calling the callback
        }

        resolve();
      };

      this.socket.onclose = () => {
        console.log("WebSocket connection closed");
        if (!this.manualClose) {
          this.hasReconnected = true; // Set to true for the next reconnection
          this.reconnect(); // Attempt to reconnect only if the closure was not manual
        }
      };

      this.socket.onerror = (error) => {
        console.error("WebSocket encountered an error:", error);
        reject("Failed to connect to WebSocket");
      };

      // Listen for messages from the server and call the onMessageCallback if available and ID matches
      this.socket.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);

        // Only trigger callback if the message ID matches the subscription ID
        if (this.onMessageCallback && data.id === this.subscriptionId) {
          this.onMessageCallback(data);
        }

        // Resolve the getInfo Promise if the message matches the getInfoId
        if (data.id === this.getInfoId && this.getInfoResolve) {
          this.getInfoResolve(data);
          this.getInfoResolve = null; // Reset resolve function after it's used
        }
      };
    });
  }

  // Public method to allow manual reconnection from the component
  public reconnect(): void {
    if (
      this.isReconnecting ||
      this.reconnectAttempts >= this.maxReconnectAttempts
    ) {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error("Max reconnect attempts reached. Unable to reconnect.");
      }
      return;
    }

    this.isReconnecting = true;
    this.reconnectAttempts++;
    console.log(
      `Reconnecting attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts}...`,
    );

    this.reconnectTimeout = setTimeout(() => {
      this.connect();
    }, this.reconnectInterval);
  }

  setOnReconnectCallback(callback: () => void): void {
    this.onReconnectCallback = callback;
  }

  private getInfoResolve: ((message: any) => void) | null = null;

  disconnect(): void {
    this.manualClose = true;
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
      console.log("WebSocket connection manually closed");
    } else {
      console.log("WebSocket is not connected or already closed");
    }

    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
  }

  // Method to request the current state with reconnection handling
  async currentState(id: number): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }

    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        // const requestId = Date.now();

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);

          // if (data.requestId === requestId) {
          //   this.socket?.removeEventListener("message", onMessage);
          //   resolve(data); // Resolve with the response data
          // }
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify({ id, method: "state", params: [] }));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to subscribe and trigger callback for each received message
  async subscribe(
    params: SubscriptionParams,
    onMessage: (message: any) => void,
  ): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: subscriptionParams } = params;

        const message = {
          id,
          method,
          params: subscriptionParams,
        };

        // Set the subscription ID and store the onMessage callback
        this.subscriptionId = id;

        // Store the onMessage callback to be called on each message
        this.onMessageCallback = onMessage;

        const onResponse = (event: MessageEvent) => {
          const data = JSON.parse(event.data);

          // Confirm the subscription
          if (data.id === id) {
            this.socket?.removeEventListener("message", onResponse);
            console.log("Subscribed successfully:", data);
            resolve(data);
          } else if (data.error) {
            console.error("Subscription failed:", data.error);
            reject(data.error);
          }
        };

        // Add the temporary listener to confirm the subscription
        this.socket.addEventListener("message", onResponse);

        // Send the subscription request
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to unsubscribe from a specific channel
  async unsubscribe(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }

    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: subscriptionParams } = params;

        const message = {
          id,
          method: method ? method : "unsubscribe",
          params: subscriptionParams,
        };

        const onResponse = (event: MessageEvent) => {
          const data = JSON.parse(event.data);

          if (data.id === id) {
            // Clear the subscription callback and ID
            this.subscriptionId = null;
            this.onMessageCallback = null;
            this.socket?.removeEventListener("message", onResponse);
            console.log("Unsubscribed successfully:", data);
            resolve(data);
          } else if (data.error) {
            console.error("Unsubscribe failed:", data.error);
            reject(data.error);
          }
        };

        // Temporary listener for unsubscribe confirmation
        this.socket.addEventListener("message", onResponse);

        // Send the unsubscribe request
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to start matchmaking
  async startMatchmaking(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: matchmakingParams } = params;

        const message = {
          id,
          method,
          params: matchmakingParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          // if (data.id === id && data.result === 'matchmaking-started') {
          //   this.socket?.removeEventListener('message', onMessage);
          //   console.log('Matchmaking started successfully:', data);
          //   resolve(data);
          // } else if (data.error) {
          //   console.error('Failed to start matchmaking:', data.error);
          //   reject(data.error);
          // }
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async cancelMatchmaking(id: number): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "cancel-matchmaking",
          params: [],
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);

          // if (data.id === id && data.result === 'matchmaking-started') {
          //   this.socket?.removeEventListener('message', onMessage);
          //   console.log('Matchmaking started successfully:', data);
          //   resolve(data);
          // } else if (data.error) {
          //   console.error('Failed to start matchmaking:', data.error);
          //   reject(data.error);
          // }
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to create room
  async createRoom(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method } = params;

        const message = {
          id,
          method,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to leave room
  async leaveRoom(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: createRoomParams } = params;

        const message = {
          id,
          method,
          params: createRoomParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to join room
  async joinRoom(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: joinRoomParams } = params;

        const message = {
          id,
          method,
          params: joinRoomParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to ready battle with frens
  async readyFrensBattle(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: joinRoomParams } = params;

        const message = {
          id,
          method,
          params: joinRoomParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to cancel ready battle with frens
  async cancelReadyFrensBattle(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: joinRoomParams } = params;

        const message = {
          id,
          method,
          params: joinRoomParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to start battle with frens
  async startFrensBattle(params: SubscriptionParams): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const { id, method, params: joinRoomParams } = params;

        const message = {
          id,
          method,
          params: joinRoomParams,
        };
        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async buildLineup(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "build-lineup",
          params: params,
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);

          // if (data.id === id && data.result === 'matchmaking-started') {
          //   this.socket?.removeEventListener('message', onMessage);
          //   console.log('Matchmaking started successfully:', data);
          //   resolve(data);
          // } else if (data.error) {
          //   console.error('Failed to start matchmaking:', data.error);
          //   reject(data.error);
          // }
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async attack(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "attack",
          params: params,
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async timeout(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "timeout",
          params: params,
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async forfeit(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "forfeit",
          params: params,
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  // Method to request info with a specific id and wait for the response
  async getInfo(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        // Store the id and resolve function
        this.getInfoId = id;
        this.getInfoResolve = resolve;

        // Send the request message to the server
        const message = {
          id,
          method: "info",
          params: params,
        };

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }

  async acceptMatch(id: number, params: any): Promise<any> {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not connected, attempting to reconnect...");
      await this.connect(); // Attempt to reconnect
    }
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const message = {
          id,
          method: "accept",
          params: params,
        };

        const onMessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          resolve(data);
        };

        this.socket.addEventListener("message", onMessage);

        this.socket.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
        reject("WebSocket is not connected");
      }
    });
  }
}

export default WS;
