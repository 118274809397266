import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const ReceiveMedal = lazy(() => import("./Pages/ReceiveMedal"));

export const medalRoute: RouteObject = {
  path: navPaths.SEED_MEDAL,
  Component: ReceiveMedal,
};
