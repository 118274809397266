import { create } from "zustand";

export const useAppTime = create<{
  now: Date;
  timer?: NodeJS.Timeout;
  startTicking: () => NodeJS.Timeout;
}>((set, get) => ({
  now: new Date(),
  timer: undefined,
  startTicking() {
    clearInterval(get().timer);
    const timer = setInterval(() => {
      set({
        now: new Date(),
      });
    }, 1000);
    set({
      timer,
    });
    return timer;
  },
}));
