import clsx from "clsx";
import { ReactNode } from "react";

const SecondaryBtn = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode | string;
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "font-baloo-paaji text-[#0B8B00] text-[24px] w-full font-bold uppercase",
        "border-[2.5px] border-[#0B8B00] py-2 bg-[#fff] shadow-[0_3px_0.5px_#0B8B00] rounded-xl",
      )}
    >
      {children}
    </button>
  );
};

export default SecondaryBtn;
