import clsx from "clsx";
import { ReactNode } from "react";

const BaseBtn = ({
  onClick,
  children,
  className,
}: {
  onClick?: () => void;
  children: ReactNode | string;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "font-baloo-paaji text-[#fff] text-[24px] text-baloo-border w-full font-bold uppercase ",
        "border-[2.5px] border-[#0B8B00] py-2 bg-[#68C022] shadow-[0_3.5px_0.5px_#0B8B00] rounded-xl",
        "active:translate-y-[3.5px] active:shadow-none",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default BaseBtn;
