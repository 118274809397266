import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const Inventory = lazy(() => import("./Pages/Inventory"));
const InventoryDetail = lazy(() => import("./Pages/InventoryDetail"));
const Bird = lazy(() => import("./Pages/Bird"));
const FeedBird = lazy(() => import("./Pages/FeedBird"));
const BirdDetails = lazy(() => import("./Pages/BirdDetails"));

export const inventoryRoute: RouteObject = {
  path: navPaths.INVENTORY,
  Component: Inventory,
};

export const inventoryDetailRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.INVENTORY_DETAIL, Component: InventoryDetail },
    { path: navPaths.BIRD, Component: Bird },
    { path: navPaths.FEED_BIRD, Component: FeedBird },
    { path: navPaths.BIRD_DETAIL, Component: BirdDetails },
  ],
};
