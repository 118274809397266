import clsx from "clsx";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBirdBattle } from "../../../../store/useBirdBattle";
// import { toast } from "react-toastify";
import { useBattleTut } from "../../../../store/useBattleTut";
import { a, useSpring } from "@react-spring/web";
import { getRandomInt, getRandomPosition } from "../../../../utils/helper";

function getPositionsByName(name: string, objects: any[]) {
  if (!objects || objects?.length === 0) return null;
  // Find the object with the matching name
  const object = objects?.find((obj: any) => obj?.name === name);
  // Return the positions if found, otherwise return null or an empty array
  return object ? object?.positions[0] : null;
}

const FireHit = () => {
  const style = useSpring({
    from: { scale: 1 },
    to: [{ scale: 1.15 }, { scale: 1 }],
    loop: true,
    config: { duration: 400 },
  });
  return (
    <a.div style={style} className="z-30">
      <img
        src="/images/battle_bird/firehit.png"
        alt="tut"
        className="size-[30px] aspect-square object-contain"
      />
    </a.div>
  );
};

const FireMiss = () => {
  const { rotateZ } = useSpring({
    from: {
      rotateZ: 0,
    },
    to: [
      {
        rotateZ: 360,
      },
      {
        rotateZ: 0,
      },
    ],
    loop: true,
    config: { tension: 70, friction: 30 },
  });
  return (
    <a.div
      style={{ transform: rotateZ.interpolate((z) => `rotateZ(${z}deg)`) }}
      className="z-30"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.125 13.5781C15.5625 14.1094 15.5625 14.6406 15.125 15.1719C14.5938 15.6094 14.0625 15.6094 13.5312 15.1719L8 9.59375L2.42188 15.1719C1.89062 15.6094 1.35938 15.6094 0.828125 15.1719C0.390625 14.6406 0.390625 14.1094 0.828125 13.5781L6.40625 8L0.828125 2.375C0.390625 1.84375 0.390625 1.3125 0.828125 0.78125C1.35938 0.34375 1.89062 0.34375 2.42188 0.78125L8 6.40625L13.5781 0.828125C14.1094 0.390625 14.6406 0.390625 15.1719 0.828125C15.6094 1.35938 15.6094 1.89062 15.1719 2.42188L9.59375 8L15.125 13.5781Z"
          fill="#C9C9C9"
        />
      </svg>
    </a.div>
  );
};

const GameMapRef = forwardRef(
  (
    {
      ready,
      yourTurn,
      accountId,
      handleAttack,
      attackResponse,
      youReady,
      rematch,
    }: {
      ready: boolean;
      yourTurn: boolean;
      lastMessage: MessageEvent<any> | null;
      accountId: string;
      handleSendMessage: (message: string) => void;
      handleAttack: (arg0: string) => void;
      attackResponse: any;
      youReady: boolean;
      rematch: boolean;
    },
    ref,
  ) => {
    const tele = window.Telegram.WebApp;

    useImperativeHandle(ref, () => ({
      fireFromParent(index: number) {
        handleFire(index);
      },
      autoArrangeFromParent() {
        handleAutoArrange();
      },
    }));

    // const [distanceInfo, setDistanceInfo] = useState<{
    //   x: number | undefined | null;
    //   y?: number | null;
    // }>({ x: 0, y: 0 });

    const check = (id: string) => {
      const blockRect = document
        .getElementById(id)
        ?.getBoundingClientRect() as DOMRect;

      // setDistanceInfo({ x: blockRect?.x, y: blockRect?.y });

      return `${
        Math.round(
          (blockRect?.x - mapContainterRect?.x) /
            (mapContainterRect?.width / 10),
        ) + 1
      },${
        Math.round(
          (blockRect?.y - mapContainterRect?.y) /
            (mapContainterRect?.height / 10),
        ) + 1
      }`;
    };

    const randomHitExclude = useBirdBattle(
      (state: any) => state.randomHitExclude,
    );
    const updateRandomHitExclude = useBirdBattle(
      (state: any) => state.updateRandomHitExclude,
    );

    // const [attackPosition, setAttackPosition] = useState<string>("");
    const [fireHitPositions, setFireHitPositions] = useState<any>([]);
    const [fireMissPositions, setFireMissPositions] = useState<any>([]);

    const [yourAttackObjects, setYourAttackObjects] = useState<any>([]);
    const [opponentAttackObjects, setOpponentAttackObjects] = useState<any>([]);

    const Seed2x2x1OpponentComplete = opponentAttackObjects?.some(
      (attObject: any) => Object.values(attObject)[0] === "Seed2x2x1",
    );
    const Seed2x2x2OpponentComplete = opponentAttackObjects?.some(
      (attObject: any) => Object.values(attObject)[0] === "Seed2x2x2",
    );
    const Seed3x3OpponentComplete = opponentAttackObjects?.some(
      (attObject: any) => Object.values(attObject)[0] === "Seed3x3",
    );
    const Tree2x3OpponentComplete = opponentAttackObjects?.some(
      (attObject: any) => Object.values(attObject)[0] === "Tree2x3",
    );
    const Tree3x4OpponentComplete = opponentAttackObjects?.some(
      (attObject: any) => Object.values(attObject)[0] === "Tree3x4",
    );

    const Seed2x2x1OpponentPosition = getPositionsByName(
      "Seed2x2x1",
      yourAttackObjects,
    );

    const Seed2x2x2OpponentPosition = getPositionsByName(
      "Seed2x2x2",
      yourAttackObjects,
    );

    const Seed3x3OpponentPosition = getPositionsByName(
      "Seed3x3",
      yourAttackObjects,
    );

    const Tree2x3OpponentPosition = getPositionsByName(
      "Tree2x3",
      yourAttackObjects,
    );

    const Tree3x4OpponentPosition = getPositionsByName(
      "Tree3x4",
      yourAttackObjects,
    );

    useEffect(() => {
      if (!attackResponse) {
        return;
      }

      const lastMessageData = attackResponse;
      if (lastMessageData?.error) {
        if (lastMessageData?.error === "position attacked") {
          return;
        }
        if (lastMessageData?.error === "not your turn") {
          return;
        }
        // toast.error(lastMessageData?.error, {
        //   style: {
        //     maxWidth: 337,
        //     height: 40,
        //     borderRadius: 8,
        //     textTransform: "capitalize",
        //   },
        //   autoClose: 2000,
        // });
        // return;
      }

      if (accountId === lastMessageData?.red_user_id) {
        setYourAttackObjects(lastMessageData?.red_attack_object);
        setOpponentAttackObjects(lastMessageData?.blue_attack_object);
      }

      if (accountId === lastMessageData?.blue_user_id) {
        setYourAttackObjects(lastMessageData?.blue_attack_object);
        setOpponentAttackObjects(lastMessageData?.red_attack_object);
      }

      const redAttackData = lastMessageData?.red_attack;
      const blueAttackData = lastMessageData?.blue_attack;

      if (lastMessageData?.is_turn === lastMessageData?.red_user_id) {
        const redAttackDataHit = redAttackData
          ?.map((item: any) => {
            var items = Object.keys(item);
            const attackPosition = items.find((key) => item[key]) as string;
            const xHit = parseInt(attackPosition?.split(",")[0]);
            const yHit = parseInt(attackPosition?.split(",")[1]);
            const position = xHit + (yHit - 1) * 10 - 1;
            return position;
          })
          ?.filter((item: any) => item !== undefined);

        const redAttackDataMiss = redAttackData
          ?.map((item: any) => {
            var items = Object.keys(item);
            const attackPosition = items.find((key) => !item[key]) as string;
            const xHit = parseInt(attackPosition?.split(",")[0]);
            const yHit = parseInt(attackPosition?.split(",")[1]);
            const position = xHit + (yHit - 1) * 10 - 1;
            return position;
          })
          ?.filter((item: any) => item !== undefined);

        setFireHitPositions(redAttackDataHit);
        setFireMissPositions(redAttackDataMiss);
      }
      if (lastMessageData?.is_turn === lastMessageData?.blue_user_id) {
        const blueAttackDataHit = blueAttackData
          ?.map((item: any) => {
            var items = Object.keys(item);
            const attackPosition = items.find((key) => item[key]) as string;
            const xHit = parseInt(attackPosition?.split(",")[0]);
            const yHit = parseInt(attackPosition?.split(",")[1]);
            const position = xHit + (yHit - 1) * 10 - 1;
            return position;
          })
          ?.filter((item: any) => item !== undefined);

        const blueAttackDataMiss = blueAttackData
          ?.map((item: any) => {
            var items = Object.keys(item);
            const attackPosition = items.find((key) => !item[key]) as string;
            const xHit = parseInt(attackPosition?.split(",")[0]);
            const yHit = parseInt(attackPosition?.split(",")[1]);
            const position = xHit + (yHit - 1) * 10 - 1;
            return position;
          })
          ?.filter((item: any) => item !== undefined);

        setFireHitPositions(blueAttackDataHit);
        setFireMissPositions(blueAttackDataMiss);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attackResponse]);

    const handleFire = (index: number) => {
      if (!yourTurn) {
        return;
      }

      tele.HapticFeedback.impactOccurred("medium");
      // auto send a fail attack when countdown complete to end turn
      // if (index === -1) {
      //   const randomX = getRandomInt(100, 200, []);
      //   const randomY = getRandomInt(201, 300, []);

      //   handleSendMessage(
      //     JSON.stringify({
      //       attack: `${randomX},${randomY}`,
      //     }),
      //   );
      //   return;
      // }

      const attackPosition = check(index.toString());
      const attPositions = [...randomHitExclude];
      attPositions.push(index);
      updateRandomHitExclude(attPositions);

      // handleSendMessage(
      //   JSON.stringify({
      //     attack: attackPosition,
      //   }),
      // );
      handleAttack(attackPosition);
      // setAttackPosition(attackPosition);
    };

    // const [onDraggingAt, setOnDraggingAt] = useState<number | undefined>();

    const mapContainer = useRef<HTMLDivElement>(null);

    const [mapContainterRect, setMapContainterRect] = useState<DOMRect>({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      toJSON: () => {},
    });

    useEffect(() => {
      if (mapContainer.current) {
        const rect = mapContainer.current.getBoundingClientRect();
        setMapContainterRect(rect);
      }
    }, []);

    const getCoordinates = useCallback(
      (id: string) => {
        if (mapContainterRect.width === 0 || mapContainterRect?.height === 0) {
          return;
        }
        const block = document.getElementById(id) as HTMLElement;
        if (!block) {
          return;
        }
        const blockRect = block.getBoundingClientRect();

        if (!blockRect) {
          return;
        }
        const calculateX = Math.round(
          (blockRect?.x - mapContainterRect?.x) /
            (mapContainterRect?.width / 10),
        );
        const calculateY = Math.round(
          (blockRect?.y - mapContainterRect?.y) /
            (mapContainterRect?.height / 10),
        );

        const rectX = Math.round(
          blockRect?.width / (mapContainterRect?.width / 10),
        );
        const rectY = Math.round(
          blockRect?.height / (mapContainterRect?.height / 10),
        );

        const x =
          calculateX + rectX - 1 > 9
            ? 9 - (rectX - 1)
            : calculateX < 0
            ? 0
            : calculateX;
        const y =
          calculateY + rectY - 1 > 9
            ? 9 - (rectY - 1)
            : calculateY < 0
            ? 0
            : calculateY;

        const arr: any[] = [];
        for (let i = 1; i <= rectX; i++) {
          for (let j = 1; j <= rectY; j++) {
            const xValue = x + i;
            const yValue = y + j;

            arr.push(JSON.stringify({ x: xValue, y: yValue }));
            arr.push(JSON.stringify({ x: xValue, y: yValue }));
          }
        }

        const arrCoord = arr.filter(
          (item, index) => arr.indexOf(item) === index,
        );
        return arrCoord;
      },
      [mapContainterRect],
    );

    const [seed1Coord, setSeed1Coord] = useState<any>();
    const [seed2Coord, setSeed2Coord] = useState<any>();
    const [seed3Coord, setSeed3Coord] = useState<any>();
    const [tree1Coord, setTree1Coord] = useState<any>();
    const [tree2Coord, setTree2Coord] = useState<any>();
    const updateLineup = useBirdBattle((state: any) => state.updateLineup);
    const [mapLineup, setMapLineup] = useState<any>([]);
    const [randomIndex, setRandomIndex] = useState<number>(
      getRandomInt(0, getRandomPosition(mapContainterRect).length - 1, []),
    );

    const handleAutoArrange = () => {
      const newRandomIndex = getRandomInt(
        0,
        getRandomPosition(mapContainterRect).length - 1,
        [randomIndex],
      );
      setRandomIndex(newRandomIndex);
    };

    const [randomPosition, setRandomPosition] = useState<any>([]);

    useEffect(() => {
      const seed1 = getCoordinates("Seed2x2x1");
      const seed2 = getCoordinates("Seed2x2x2");
      const seed3 = getCoordinates("Seed3x3");
      const tree1 = getCoordinates("Tree2x3");
      const tree2 = getCoordinates("Tree3x4");

      if (seed1) setSeed1Coord(seed1);
      if (seed2) setSeed2Coord(seed2);
      if (seed3) setSeed3Coord(seed3);
      if (tree1) setTree1Coord(tree1);
      if (tree2) setTree2Coord(tree2);
    }, [getCoordinates, randomIndex, randomPosition]);

    useEffect(() => {
      if (seed1Coord && seed2Coord && seed3Coord && tree1Coord && tree2Coord) {
        setMapLineup([
          ...seed1Coord,
          ...seed2Coord,
          ...seed3Coord,
          ...tree1Coord,
          ...tree2Coord,
        ]);

        updateLineup([
          {
            Name: "Seed2x2x1",
            Positions: [...seed1Coord].map((coord) => JSON.parse(coord)),
          },
          {
            Name: "Seed2x2x2",
            Positions: [...seed2Coord].map((coord) => JSON.parse(coord)),
          },
          {
            Name: "Seed3x3",
            Positions: [...seed3Coord].map((coord) => JSON.parse(coord)),
          },
          {
            Name: "Tree2x3",
            Positions: [...tree1Coord].map((coord) => JSON.parse(coord)),
          },
          {
            Name: "Tree3x4",
            Positions: [...tree2Coord].map((coord) => JSON.parse(coord)),
          },
        ]);
      }
    }, [
      seed1Coord,
      seed2Coord,
      seed3Coord,
      tree1Coord,
      tree2Coord,
      updateLineup,
      randomIndex,
    ]);

    const handleTouchMove = (
      e: React.TouchEvent<HTMLDivElement>,
      id: string,
    ) => {
      if (youReady) {
        return;
      }
      const block = document.getElementById(id) as HTMLElement;
      var touchLocation = e.targetTouches[0];
      block.style.left = touchLocation.clientX - block.clientWidth / 2 + "px";
      block.style.top = touchLocation.clientY - block.clientHeight / 2 + "px";
    };

    const handleTouchEnd = (id: string) => {
      const block = document.getElementById(id) as HTMLElement;
      const blockRect = block.getBoundingClientRect();

      const blockCoord = getCoordinates(id);

      let prevBlockCoord = seed1Coord;
      if (id === "Seed2x2x1") prevBlockCoord = seed1Coord;
      if (id === "Seed2x2x2") prevBlockCoord = seed2Coord;
      if (id === "Seed3x3") prevBlockCoord = seed3Coord;
      if (id === "Tree2x3") prevBlockCoord = tree1Coord;
      if (id === "Tree3x4") prevBlockCoord = tree2Coord;

      const mergedCoordWithoutDuplicates = mapLineup?.filter((block: any) => {
        return (
          blockCoord &&
          blockCoord.includes(block) &&
          !prevBlockCoord.includes(block)
        );
      });

      const blockSize = Math.ceil(
        (blockRect.width / mapContainterRect.width) * 10,
      );
      let duplicate = false;

      mergedCoordWithoutDuplicates?.forEach((blockCoordinate: any) => {
        if (blockCoord?.includes(blockCoordinate)) {
          // toast.error("Coordinates are duplicated or out of range", {
          //   style: { maxWidth: 337, height: 55, borderRadius: 8 },
          //   autoClose: 500,
          //   toastId: "duplicate-coordinates",
          // });

          const nextBlockCoordX =
            JSON.parse(prevBlockCoord[0]).x - 1 + blockSize > 10
              ? 10 - blockSize
              : JSON.parse(prevBlockCoord[0]).x - 1
              ? JSON.parse(prevBlockCoord[0]).x - 1
              : 0;

          const nextBlockCoordY =
            JSON.parse(prevBlockCoord[0]).y - 1 > 0
              ? JSON.parse(prevBlockCoord[0]).y - 1
              : JSON.parse(prevBlockCoord[0]).y - 1 > 10
              ? 10
              : 0;

          const xDefault =
            (mapContainterRect.width / 10) * nextBlockCoordX +
            mapContainterRect.x;
          const yDefault =
            (mapContainterRect.width / 10) * nextBlockCoordY +
            mapContainterRect.y;
          block.style.left = xDefault + "px";
          block.style.top = yDefault + "px";
          duplicate = true;
        }
      });

      if (!duplicate) {
        if (id === "Seed2x2x1") setSeed1Coord(blockCoord);
        if (id === "Seed2x2x2") setSeed2Coord(blockCoord);
        if (id === "Seed3x3") setSeed3Coord(blockCoord);
        if (id === "Tree2x3") setTree1Coord(blockCoord);
        if (id === "Tree3x4") setTree2Coord(blockCoord);

        const newLeft =
          blockRect.x + blockRect.width >
          mapContainterRect.width + mapContainterRect.x
            ? mapContainterRect.width + mapContainterRect.x - blockRect.width
            : blockRect.x < mapContainterRect.x
            ? mapContainterRect.x
            : Math.round(
                (blockRect.x - mapContainterRect.x) /
                  (mapContainterRect?.width / 10),
              ) *
                (mapContainterRect?.width / 10) +
              mapContainterRect.x;
        const newTop =
          blockRect.y + blockRect.height >
          mapContainterRect.height + mapContainterRect.y
            ? mapContainterRect.height + mapContainterRect.y - blockRect.height
            : blockRect.y < mapContainterRect.y
            ? mapContainterRect.y
            : Math.round(
                (blockRect.y - mapContainterRect.y) /
                  (mapContainterRect?.height / 10),
              ) *
                (mapContainterRect?.height / 10) +
              mapContainterRect.y;
        block.style.left = newLeft + "px";
        block.style.top = newTop + "px";

        const newDisplayPosition = [...randomPosition];
        const newDisplay = {
          ...randomPosition[randomIndex],
          [`${id}Left`]: newLeft,
          [`${id}Top`]: newTop,
        };

        newDisplayPosition.push(newDisplay);
        setRandomPosition(newDisplayPosition);
        setRandomIndex(newDisplayPosition.length - 1);
      }
    };

    const showBlock = useMemo(() => {
      if (!ready) {
        return true;
      }
      return !yourTurn;
    }, [yourTurn, ready]);

    // const blockArray = useMemo(() => {
    //   if (roomType?.size === "5x5") {
    //     return Array.from({ length: 25 });
    //   }
    //   if (roomType?.size === "8x8") {
    //     return Array.from({ length: 64 });
    //   }
    //   return Array.from({ length: 100 });
    // }, [roomType]);
    const blockArray = Array.from({ length: 100 });

    useEffect(() => {
      setRandomPosition(getRandomPosition(mapContainterRect));
    }, [mapContainterRect]);

    const firstTime = useBattleTut((state: any) => state.firstTime);
    const setFirsTime = useBattleTut((state: any) => state.setFirsTime);

    // rematch
    const lineupRematch =
      accountId === attackResponse?.red_user_id
        ? attackResponse?.red_lineup
        : attackResponse?.blue_lineup;

    const Seed2x2x1Rematch = getPositionsByName("Seed2x2x1", lineupRematch);
    const Seed2x2x2Rematch = getPositionsByName("Seed2x2x2", lineupRematch);
    const Seed3x3Rematch = getPositionsByName("Seed3x3", lineupRematch);
    const Tree2x3Rematch = getPositionsByName("Tree2x3", lineupRematch);
    const Tree3x4Rematch = getPositionsByName("Tree3x4", lineupRematch);

    return (
      <div
        ref={mapContainer}
        className="grid grid-cols-10 overflow-hidden border border-[#FFFFFF66] bg-[#00000099] select-none w-full aspect-square z-10"
        // onDragOver={(e) => {
        //   // e.preventDefault();
        //   const target = e.target as HTMLElement;
        //   if (target.id) {
        //     setOnDraggingAt(parseInt(target.id));
        //   }
        // }}
        // onDrop={(e) => {
        //   const target = e.target as HTMLElement;
        //   if (target.id) {
        //     setOnDraggingAt(parseInt(target.id));
        //   }
        // }}
      >
        {blockArray.map((_, index) => {
          return (
            <div
              key={index}
              id={index.toString()}
              onClick={() => handleFire(index)}
              className={clsx(
                "aspect-square select-none border border-[#FFFFFF66] z-10",
                // index === onDraggingAt && "bg-[#7BE527]/50",
                // index === firedBlock && "bg-[#7BE527]",
                "flex items-center justify-center",
              )}
              style={{
                width: mapContainterRect?.width / Math.sqrt(blockArray.length),
              }}
            >
              {fireHitPositions?.includes(index) && <FireHit />}
              {fireMissPositions?.includes(index) && <FireMiss />}
            </div>
          );
        })}

        {firstTime && yourTurn && ready && (
          <div
            onClick={() => setFirsTime(false)}
            className="fixed inset-0 mx-auto my-auto flex flex-col justify-center items-center z-30 bg-[#00000070]"
          >
            <img
              src="/images/battle_bird/tut.png"
              alt="tut"
              className="w-[100px] aspect-square object-contain battle-tut"
            />
            <span className="bg-white text-black font-medium px-2 py-1 rounded-lg mt-2">
              Tap to shoot at the enemy's field
            </span>
          </div>
        )}

        {!showBlock && Seed2x2x1OpponentPosition && (
          <div
            className={clsx(
              "fixed aspect-square border border-transparent bg-contain bg-no-repeat filter grayscale",
              !ready && "bg-[#FFFFFF33] z-10 ",
            )}
            style={{
              backgroundImage: `url(/images/battle_bird/seed-block.png)`,
              width: (mapContainterRect?.width / 10) * 2,
              left:
                mapContainterRect.x +
                (mapContainterRect.width / 10) *
                  (Seed2x2x1OpponentPosition?.x - 1),
              top:
                mapContainterRect.y +
                (mapContainterRect.height / 10) *
                  (Seed2x2x1OpponentPosition?.y - 1),
            }}
          />
        )}

        {!showBlock && Seed2x2x2OpponentPosition && (
          <div
            className={clsx(
              "fixed aspect-square border border-transparent bg-contain bg-no-repeat filter grayscale",
              !ready && "bg-[#FFFFFF33] z-10 ",
            )}
            style={{
              backgroundImage: `url(/images/battle_bird/seed-block.png)`,
              width: (mapContainterRect?.width / 10) * 2,
              left:
                mapContainterRect.x +
                (mapContainterRect.width / 10) *
                  (Seed2x2x2OpponentPosition?.x - 1),
              top:
                mapContainterRect.y +
                (mapContainterRect.height / 10) *
                  (Seed2x2x2OpponentPosition?.y - 1),
            }}
          />
        )}

        {!showBlock && Seed3x3OpponentPosition && (
          <div
            className={clsx(
              "fixed aspect-square border border-transparent bg-contain bg-no-repeat filter grayscale",
              !ready && "bg-[#FFFFFF33] z-10 ",
            )}
            style={{
              backgroundImage: `url(/images/battle_bird/seed-block.png)`,
              width: (mapContainterRect?.width / 10) * 3,
              left:
                mapContainterRect.x +
                (mapContainterRect.width / 10) *
                  (Seed3x3OpponentPosition?.x - 1),
              top:
                mapContainterRect.y +
                (mapContainterRect.height / 10) *
                  (Seed3x3OpponentPosition?.y - 1),
            }}
          />
        )}

        {!showBlock && Tree2x3OpponentPosition && (
          <div
            className={clsx(
              "fixed border border-transparent bg-contain bg-no-repeat filter grayscale",
              !ready && "bg-[#FFFFFF33] z-10 ",
            )}
            style={{
              backgroundImage: `url(/images/battle_bird/Tree2x3.png)`,
              width: (mapContainterRect?.width / 10) * 3,
              height: (mapContainterRect?.width / 10) * 2,
              left:
                mapContainterRect.x +
                (mapContainterRect.width / 10) *
                  (Tree2x3OpponentPosition?.x - 1),
              top:
                mapContainterRect.y +
                (mapContainterRect.height / 10) *
                  (Tree2x3OpponentPosition?.y - 1),
            }}
          />
        )}

        {!showBlock && Tree3x4OpponentPosition && (
          <div
            className={clsx(
              "fixed border border-transparent bg-contain bg-no-repeat filter grayscale",
              !ready && "bg-[#FFFFFF33] z-10 ",
            )}
            style={{
              backgroundImage: `url(/images/battle_bird/Tree3x4.png)`,
              backgroundSize: "100% 100%",
              width: (mapContainterRect?.width / 10) * 3,
              height: (mapContainterRect?.width / 10) * 4,
              left:
                mapContainterRect.x +
                (mapContainterRect.width / 10) *
                  (Tree3x4OpponentPosition?.x - 1),
              top:
                mapContainterRect.y +
                (mapContainterRect.height / 10) *
                  (Tree3x4OpponentPosition?.y - 1),
            }}
          />
        )}

        {showBlock && (
          <>
            <div
              id="Seed2x2x1"
              className={clsx(
                "fixed aspect-square border border-transparent bg-contain bg-no-repeat filter",
                !ready && "bg-[#FFFFFF33] z-10",
                Seed2x2x1OpponentComplete && "grayscale",
              )}
              style={{
                backgroundImage: `url(/images/battle_bird/seed-block.png)`,
                width: (mapContainterRect?.width / 10) * 2,
                left:
                  rematch || ready
                    ? mapContainterRect.x +
                      (mapContainterRect.width / 10) * (Seed2x2x1Rematch?.x - 1)
                    : randomPosition[randomIndex]?.Seed2x2x1Left,
                top:
                  rematch || ready
                    ? mapContainterRect.y +
                      (mapContainterRect.width / 10) * (Seed2x2x1Rematch?.y - 1)
                    : randomPosition[randomIndex]?.Seed2x2x1Top,
              }}
              onTouchMove={(e) => handleTouchMove(e, "Seed2x2x1")}
              onTouchEnd={() => handleTouchEnd("Seed2x2x1")}
            />

            <div
              id="Seed2x2x2"
              className={clsx(
                "fixed aspect-square border border-transparent bg-contain bg-no-repeat",
                !ready && "bg-[#FFFFFF33] z-10",
                Seed2x2x2OpponentComplete && "grayscale",
              )}
              style={{
                backgroundImage: `url(/images/battle_bird/seed-block.png)`,
                width: (mapContainterRect?.width / 10) * 2,
                left:
                  rematch || ready
                    ? mapContainterRect.x +
                      (mapContainterRect.width / 10) * (Seed2x2x2Rematch?.x - 1)
                    : randomPosition[randomIndex]?.Seed2x2x2Left,
                top:
                  rematch || ready
                    ? mapContainterRect.y +
                      (mapContainterRect.width / 10) * (Seed2x2x2Rematch?.y - 1)
                    : randomPosition[randomIndex]?.Seed2x2x2Top,
              }}
              onTouchMove={(e) => handleTouchMove(e, "Seed2x2x2")}
              onTouchEnd={() => handleTouchEnd("Seed2x2x2")}
            />

            <div
              id="Seed3x3"
              className={clsx(
                "fixed aspect-square border border-transparent bg-contain bg-no-repeat",
                !ready && "bg-[#FFFFFF33] z-10",
                Seed3x3OpponentComplete && "grayscale",
              )}
              style={{
                backgroundImage: `url(/images/battle_bird/seed-block.png)`,
                width: (mapContainterRect?.width / 10) * 3,
                left:
                  rematch || ready
                    ? mapContainterRect.x +
                      (mapContainterRect.width / 10) * (Seed3x3Rematch?.x - 1)
                    : randomPosition[randomIndex]?.Seed3x3Left,
                top:
                  rematch || ready
                    ? mapContainterRect.y +
                      (mapContainterRect.width / 10) * (Seed3x3Rematch?.y - 1)
                    : randomPosition[randomIndex]?.Seed3x3Top,
              }}
              onTouchMove={(e) => handleTouchMove(e, "Seed3x3")}
              onTouchEnd={() => handleTouchEnd("Seed3x3")}
            />

            <div
              id="Tree2x3"
              className={clsx(
                "fixed border border-transparent bg-no-repeat",
                !ready && "bg-[#FFFFFF33] z-10",
                Tree2x3OpponentComplete && "grayscale",
              )}
              style={{
                backgroundImage: `url(/images/battle_bird/Tree2x3.png)`,
                backgroundSize: "100% 100%",
                width: (mapContainterRect?.width / 10) * 3,
                height: (mapContainterRect?.width / 10) * 2,
                left:
                  rematch || ready
                    ? mapContainterRect.x +
                      (mapContainterRect.width / 10) * (Tree2x3Rematch?.x - 1)
                    : randomPosition[randomIndex]?.Tree2x3Left,
                top:
                  rematch || ready
                    ? mapContainterRect.y +
                      (mapContainterRect.width / 10) * (Tree2x3Rematch?.y - 1)
                    : randomPosition[randomIndex]?.Tree2x3Top,
              }}
              onTouchMove={(e) => handleTouchMove(e, "Tree2x3")}
              onTouchEnd={() => handleTouchEnd("Tree2x3")}
            />

            <div
              id="Tree3x4"
              className={clsx(
                "fixed border border-transparent bg-no-repeat",
                !ready && "bg-[#FFFFFF33] z-10",
                Tree3x4OpponentComplete && "grayscale",
              )}
              style={{
                backgroundImage: `url(/images/battle_bird/Tree3x4.png)`,
                backgroundSize: "100% 100%",
                width: (mapContainterRect?.width / 10) * 3,
                height: (mapContainterRect?.width / 10) * 4,
                left:
                  rematch || ready
                    ? mapContainterRect.x +
                      (mapContainterRect.width / 10) * (Tree3x4Rematch?.x - 1)
                    : randomPosition[randomIndex]?.Tree3x4Left,
                top:
                  rematch || ready
                    ? mapContainterRect.y +
                      (mapContainterRect.width / 10) * (Tree3x4Rematch?.y - 1)
                    : randomPosition[randomIndex]?.Tree3x4Top,
              }}
              onTouchMove={(e) => handleTouchMove(e, "Tree3x4")}
              onTouchEnd={() => handleTouchEnd("Tree3x4")}
            />
          </>
        )}
      </div>
    );
  },
);

const GameMap10x10 = memo(GameMapRef);

export default GameMap10x10;
