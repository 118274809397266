import clsx from "clsx";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useBirdBattle } from "../../../../store/useBirdBattle";
import BaseBtn from "../BaseBtn";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../../constants/navbar.constants";
import { a, useSpring, useSpringRef } from "@react-spring/web";

const MatchFound = ({
  matchFound,
  setMatchFound,
  allPlayerAccepted,
  allPlayerMatchingSuccessful,
  ws,
  message,
}: {
  matchFound: boolean;
  setMatchFound: Dispatch<SetStateAction<boolean>>;
  allPlayerAccepted: boolean;
  allPlayerMatchingSuccessful: boolean;
  ws: any;
  message: any;
}) => {
  const navigate = useNavigate();
  const match = useBirdBattle((state: any) => state.match);
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);
  const updateUsers = useBirdBattle((state: any) => state.updateUsers);

  // const [showMatchFound, setShowMatchFound] = useState(false);

  const showMatchFound = useMemo(() => {
    if (allPlayerMatchingSuccessful && !allPlayerAccepted) {
      return true;
    }
    return false;
  }, [allPlayerAccepted, allPlayerMatchingSuccessful]);

  const [disableAccept, setDisableAccept] = useState(false);

  // Accept matchmaking action
  const handleAcceptMatchmaking = () => {
    ws.acceptMatch(Date.now(), [match?.current_match_id])
      .then(() => {
        waitingRef.start();
        setDisableAccept(true);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (message?.status === "ended") {
      setDisableAccept(true);
      setTimeout(() => {
        updateMatch(null);
        updateUsers([]);
        navigate(navPaths.BATTLE_ONLINE);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const waitingRef = useSpringRef();
  const style = useSpring({
    ref: waitingRef,
    from: { opacity: 0, scale: 1.4 },
    to: { opacity: 1, scale: 1 },
    config: { tension: 180, friction: 20 },
  });

  // const users = useBirdBattle((state: any) => state.users);

  if (!allPlayerMatchingSuccessful) return null;

  return (
    <div
      className={clsx(
        "h-screen flex flex-col items-center justify-center gap-6 absolute inset-0 transition-all ease-linear z-50 bg-[#000000CC] backdrop-blur-sm",
        showMatchFound ? "translate-y-0" : "translate-y-[100%]",
      )}
    >
      <div className="font-baloo-paaji font-bold text-[32px] text-white z-30 uppercase">
        Match Found
      </div>
      {showMatchFound && (
        <div className="relative h-[10px] rounded-2xl bg-[#FFFFFF33] w-[248px]">
          <div className="bg-white h-[10px] rounded-2xl absolute inset-0 loadingMatchFound" />
        </div>
      )}
      <div className="w-[154px] mt-5">
        <BaseBtn
          className={clsx(
            disableAccept &&
              "bg-[#B3B3B3] border-[#606060] shadow-[0_3.5px_0.5px_#606060] !text-[#515151] text-baloo-border-none !pointer-events-none",
          )}
          onClick={handleAcceptMatchmaking}
        >
          Accept!
        </BaseBtn>
      </div>
      {/* <div className="flex items-center justify-center gap-2">
        <div
          className={clsx(
            "size-[60px] rounded-[50%] border-[2px] border-[#FFFFFF33] overflow-hidden",
            "flex items-center justify-center",
          )}
        >
          <img
            src={`https://konsen-image-proxy.taskfi.io/proxy/${users[0]?.name}`}
            alt=""
          ></img>
        </div>
        <div
          className={clsx(
            "size-[60px] rounded-[50%] border-[2px] border-[#FFFFFF33] overflow-hidden",
            "flex items-center justify-center",
          )}
        >
          <img
            src={`https://konsen-image-proxy.taskfi.io/proxy/${users[0]?.name}`}
            alt=""
          ></img>
        </div>
      </div> */}
      <a.div
        style={style}
        className={clsx(
          "font-semibold text-white z-30",
          // youAccept ? "visible" : "invisible",
        )}
      >
        Waiting for opponent...
      </a.div>
    </div>
  );
};

export default MatchFound;
