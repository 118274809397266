import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useBattleTut = create(
  persist(
    (set) => ({
      firstTime: true,
      setFirsTime: (firstTime: boolean) => set({ firstTime: firstTime }),
    }),
    {
      name: "first-time-battle",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
