import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const BirdCage = lazy(() => import("./Pages/BirdCage"));

export const birdCageRoute: RouteObject = {
  path: navPaths.BIRD_CAGE,
  children: [{ path: "", Component: BirdCage }],
};
