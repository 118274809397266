import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Bird } from "../types/bird";

export const useBirdStore = create(
  persist(
    (set) => ({
      bird: null,
      setBirddItem: (bird: any) => set({ bird: bird }),
      birdInCage: null,
      setBirdInCage: (birdInCage: Bird) => set({ birdInCage: birdInCage }),
      birdMission: false,
      setBirdMission: (birdMission: any) => set({ birdMission: birdMission }),
    }),
    {
      name: "bird-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
