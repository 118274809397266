import axios from "axios";
import { Result } from "../types/utils";

export const getTelegramData = () => {
  const telegram = window.Telegram?.WebApp;
  if (!telegram) {
    return "";
  }

  return telegram.initData;
  // return process.env.REACT_APP_TELE_ID || "";
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "telegram-data": getTelegramData(),
  },
});

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const fetchApi = async (
  method: HttpMethod,
  url: string,
  body?: string,
): Promise<Result<Response, Error>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const telegramData = getTelegramData();

  const headers = new Headers({
    "Telegram-Data": telegramData,
  });

  try {
    return {
      data: await fetch(`${baseUrl}${url}`, {
        method,
        headers,
        body,
      }),
    };
  } catch (error) {
    return { error: error as Error };
  }
};
