import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import DeoployBird from "./Pages/DeployBirdScreen";
import EndGame from "./Pages/EndGame";
import FindMatchScreen from "./Pages/FindMatchScreen";
import HomeScreen from "./Pages/HomeScreen";
import PlayOnlineScreen from "./Pages/PlayOnlineScreen";

export const battleBirdRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.BATTLE_HOME, Component: HomeScreen },
    { path: navPaths.BATTLE_ONLINE, Component: PlayOnlineScreen },
    { path: navPaths.FIND_MATCH, Component: FindMatchScreen },
    { path: navPaths.DEPLOY_BIRD, Component: DeoployBird },
    { path: navPaths.END_GAME, Component: EndGame },
  ],
};
