import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const DailyStreak = lazy(() => import("./pages/DailyStreak"));

export const streakRoute: RouteObject = {
  path: navPaths.STREAK,
  Component: DailyStreak,
};
