import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";
import { useBirdBattle } from "../../../store/useBirdBattle";
import { getNumberFormatUs } from "../../../utils/formatNumber";

const ROOM = [
  {
    name: "tokyo",
    id: "11689874-d780-44b4-b859-077764e9bc89",
    reward: "uncommon",
  },
  {
    name: "sydney",
    id: "1e1f0d16-7aa1-41f9-adc7-99f73ea31517",
    reward: "rare",
  },
  {
    name: "las_vegas",
    id: "a38dfda9-d757-40ab-bdf8-51f78a92872d",
    reward: "epic",
  },
  {
    name: "paris",
    id: "0368ad6d-c3d8-41f9-ad76-b8d903fce852",
    reward: "legendary",
  },
  {
    name: "london",
    id: "2df03311-0296-4022-8010-23d2c19bb8e9",
    reward: "rare",
    reward2: "epic",
  },
  {
    name: "dubai",
    id: "41073006-c1a3-45b6-9201-346cbd08ad9b",
    reward: "rare",
    reward2: "epic",
  },
  {
    name: "new_york",
    id: "afba9be9-b547-4868-a7ef-24fb8ba61691",
    reward: "epic",
    reward2: "legendary",
  },
];

// const Lock = () => {
//   return (
//     <svg
//       width="50"
//       height="56"
//       viewBox="0 0 50 56"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g filter="url(#filter0_d_10225_4170)">
//         <path
//           fill-rule="evenodd"
//           clip-rule="evenodd"
//           d="M46 27C46 23.7188 43.2812 21 40 21H38.5V13.5C38.5 6.09375 32.4062 0 25 0C17.5 0 11.5 6.09375 11.5 13.5V21H10C6.625 21 4 23.625 4 27V42C4 45.2812 6.625 48 10 48H40C43.2812 48 46 45.2812 46 42V27ZM17.5 21V13.5C17.5 9.375 20.7812 6 25 6C29.125 6 32.5 9.375 32.5 13.5V21H17.5ZM30.0454 31.7727C30.0454 33.6886 28.9165 35.3408 27.2877 36.1005L29.5 42H20.5L22.8033 35.8578C21.4227 35.0214 20.5 33.5048 20.5 31.7727C20.5 29.1368 22.6368 27 25.2727 27C27.9086 27 30.0454 29.1368 30.0454 31.7727Z"
//           fill="white"
//         />
//       </g>
//       <defs>
//         <filter
//           id="filter0_d_10225_4170"
//           x="0"
//           y="0"
//           width="50"
//           height="56"
//           filterUnits="userSpaceOnUse"
//           color-interpolation-filters="sRGB"
//         >
//           <feFlood flood-opacity="0" result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy="4" />
//           <feGaussianBlur stdDeviation="2" />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_10225_4170"
//           />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="effect1_dropShadow_10225_4170"
//             result="shape"
//           />
//         </filter>
//       </defs>
//     </svg>
//   );
// };

const PlayModeItem = ({
  item,
  isActive,
  index,
}: {
  item: any;
  isActive: boolean;
  index: number;
}) => {
  const name = ROOM.find((room) => room.id === item.id)?.name;
  const reward = ROOM.find((room) => room.id === item.id)?.reward;
  const reward2 = ROOM.find((room) => room.id === item.id)?.reward2;

  const navigate = useNavigate();
  const updateRoomType = useBirdBattle((state: any) => state.updateRoomType);
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);
  const updateUsers = useBirdBattle((state: any) => state.updateUsers);

  const handleStartFindMatch = () => {
    updateMatch(null);
    updateUsers([]);
    updateRoomType(item);
    // navigate(navPaths.FIND_MATCH);
    navigate(navPaths.DEPLOY_BIRD);
  };

  const fee = item?.entry_fee / 10 ** 9;

  return (
    <div
      className={clsx(
        "p-[18px] pb-[22px] rounded-3xl relative overflow-hidden",
        "flex flex-col items-center gap-2",
        isActive
          ? "border-[3px] border-[#fff] shadow-[0_0_16px_#075E00B2] bg-[#fff] w-full pt-[30%]"
          : "border-[3px] border-[#fff] bg-transparent w-[90%] opacity-60 blur-[0.5px] h-[95%] pt-[25%]",
      )}
      style={{
        backgroundSize: "110% 120%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(/images/battle_bird/background/${name}.webp)`,
      }}
    >
      {/* {index >= 5 && (
        <div className="absolute inset-0 bg-[#000000CC] z-30 flex items-center justify-center">
          <Lock />
        </div>
      )} */}
      <img
        src={`images/battle_bird/${name}.webp`}
        alt=""
        className="absolute inset-0 w-full object-center z-10"
      />
      {/* <div className=" absolute inset-0 backdrop-blur-[1px] z-0" /> */}
      <div
        className={clsx(
          "w-full rounded-lg h-[47px] py-[9px] px-4 overflow-hidden",
          "flex gap-[10px] items-center text-white",
          "bg-[#00000060] border-transparent z-10 relative",
        )}
      >
        <div className="absolute inset-0 z-0 rounded-lg">
          <img alt="" src={`images/battle_bird/shine.png`} />
        </div>
        <p className="text-[18px] font-bold font-baloo-paaji drop-shadow-[0_1px_0.54px_#053C00] text-[#fff] mr-2">
          Prize:
        </p>
        <div
          className={clsx(
            "flex items-center justify-center gap-2 z-10 flex-1",
            isActive ? "text-[18px]" : "text-base",
          )}
        >
          <div className="flex items-center gap-1">
            <span className="text-white font-bold font-baloo-paaji mt-[2px]">
              {getNumberFormatUs(item?.seed_reward / 10 ** 9)}
            </span>
            <img
              className="size-[24px]"
              src={`/images/battle_bird/seed.png`}
              alt=""
            ></img>
          </div>
          {index >= 4 && (
            <div className="flex items-center gap-1">
              <span className="text-white font-bold font-baloo-paaji mt-[2px]">
                + 30%
              </span>
              <img
                className="size-[22px] object-contain mb-[1px]"
                src={`/images/lucky_spin/${reward}.png`}
                alt=""
              ></img>
              /
              <img
                className="size-[22px] object-contain mb-[1px]"
                src={`/images/lucky_spin/${reward2}.png`}
                alt=""
              ></img>
            </div>
          )}
        </div>
      </div>

      <div
        className={clsx(
          "flex gap-3 items-center justify-between w-full pl-1 mt-1 z-10",
          "text-[#fff]",
        )}
      >
        <div className="font-semibold text-sm mt-1 [text-shadow:_2px_1px_0_rgb(0_0_0_/_60%)]">
          {/* {`Online: ${getNumberFormatUs(
          item?.online,
        )}`} */}
        </div>
        {/* <div className="font-medium text-sm">{`Size: ${item.size}`}</div> */}
        {/* <div className="font-medium text-sm mb-[2px]">•</div> */}

        <button
          className={clsx(
            "bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_3.5px_0.5px_#0B8B00] font-baloo-paaji font-bold text-white",
            "z-50 border-none rounded-lg !text-black text-baloo-border-none flex items-center justify-center text-sm gap-2 px-4 py-[6px] w-fit",
            "active:translate-y-[3.5px] active:shadow-none",
          )}
          onClick={handleStartFindMatch}
        >
          Play
          <div className="flex items-center gap-1">
            <img
              src="/images/icons/token_icon.png?v=3"
              width={14}
              height={14}
              alt="token"
            ></img>
            <p className="font-baloo-paaji">{getNumberFormatUs(fee)}</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PlayModeItem;
