import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useBirdBattle } from "../../../store/useBirdBattle";
import BaseBtn from "../Components/BaseBtn";
// import { toast } from "react-toastify";
import { isDesktop, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import ModalContainer from "../../../components/common/ModalContainer";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import WS from "../../../config/websocketWrapper";
import { navPaths } from "../../../constants/navbar.constants";
// import { telegramID } from "../../../utils/helper";
import CustomCountdown from "../Components/DeployBird/CustomCountdown";
import GameMap10x10 from "../Components/DeployBird/GameMap10x10";
import MatchFound from "../Components/DeployBird/MatchFound";
import PublicMatchMaking from "../Components/MatchMaking/PublicMatchMaking";
import CreatematchModal from "../Components/Modal/CreateMatchModal";
import PickTurn from "../Pages/PickTurn";

const DeployBird = () => {
  const navigate = useNavigate();

  const tele = window.Telegram.WebApp;
  const telegramID = encodeURIComponent(tele?.initData);

  // tele.BackButton.hide();

  tele.BackButton.onClick(() => handleBackButton());

  const gameMapRef = useRef<{
    fireFromParent: any;
    autoArrangeFromParent: any;
  }>(null);

  const match = useBirdBattle((state: any) => state.match);

  const lineup = useBirdBattle((state: any) => state.lineup);
  const updateLineup = useBirdBattle((state: any) => state.updateLineup);
  const playWithFrens = useBirdBattle((state: any) => state.playWithFrens);

  const [ready, setReady] = useState<boolean>(false);
  const [matchMaking, setMatchMaking] = useState<boolean>(true);

  const [retreatId, setRetreatId] = useState<any>();
  const handleBackButton = () => {
    setRetreatId(match?.current_match_id);
    if (!matchMaking && turn) {
      setMatchMaking(false);
      // navigate(navPaths.DEPLOY_BIRD);
      setShowRetreatModal(true);
      return;
    }
    if (playWithFrens) {
      navigate(navPaths.BATTLE_HOME);
    } else {
      navigate(navPaths.BATTLE_ONLINE);
    }
    updateRandomHitExclude([]);
    updateMatch(null);
    updateUsers([]);
    updateLineup([]);
    handleCancelMatchmaking();
  };

  const [turn, setTurn] = useState<number>(0);

  const { accountV2 } = useAccountV2();

  const [showPickTurn, setShowPickTurn] = useState<number>(0);

  useEffect(() => {
    if (showPickTurn === 2) {
      // gameCountDownRef.current?.restartFromParent();
      setReady(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPickTurn]);

  // Endgame
  // const endgame = useBirdBattle((state: any) => state.endgame);
  const updateEndgame = useBirdBattle((state: any) => state.updateEndgame);
  const updateRandomHitExclude = useBirdBattle(
    (state: any) => state.updateRandomHitExclude,
  );
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);

  const Loader = () => {
    return (
      <div className="absolute z-20 inset-0 backdrop-blur-[2px] flex items-center justify-center">
        <div className="game-loader-2"></div>
      </div>
    );
  };

  const [showRetreatModal, setShowRetreatModal] = useState<boolean>(false);

  // const gameCountDownRef = useRef<{ restartFromParent: any }>(null);

  const WEBSOCKET_URL = process.env.REACT_APP_SOCKET_URL;
  const BATTLE_SOCKET_URL = `${WEBSOCKET_URL}?telegram_data=${telegramID}`;
  const ws = useMemo(() => new WS(BATTLE_SOCKET_URL), [BATTLE_SOCKET_URL]);

  const [youReady, setYouReady] = useState<boolean>(false);

  const handleBuildLineup = (lineup: any) => {
    const params = [
      match?.current_match_id
        ? match?.current_match_id
        : ingameMessage?.current_match_id,
      lineup,
    ];

    ws.buildLineup(Date.now(), params)
      .then(async (ingameMessage) => {
        const matchData = ingameMessage?.data?.match;

        if (!matchData) {
          return;
        }
        setInGameMessage({
          ...matchData,
          egg_piece_type: ingameMessage?.data?.egg_piece_type,
          egg_piece_id: ingameMessage?.data?.egg_piece_id,
        });
        const redStatus = matchData?.red_status;
        const blueStatus = matchData?.blue_status;
        if (
          redStatus === "build_lineup_done" &&
          accountV2?.data?.id === matchData?.red_user_id
        ) {
          setYouReady(true);
        }
        if (
          blueStatus === "build_lineup_done" &&
          accountV2?.data?.id === matchData?.blue_user_id
        ) {
          setYouReady(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [message, setMessage] = useState<any>();
  const [ingameMessage, setInGameMessage] = useState<any>();

  useEffect(() => {
    if (ingameMessage?.winner && ingameMessage?.loser) {
      updateEndgame({
        winner: ingameMessage?.winner,
        loser: ingameMessage?.loser,
        ...ingameMessage,
      });
      // handleCloseSocket();
      updateRandomHitExclude([]);
      updateMatch(null);
      updateUsers([]);
      updateLineup([]);
      navigate(navPaths.END_GAME);
    }

    if (
      (ingameMessage?.message === "match already ended" ||
        ingameMessage?.status === "ended") &&
      !turn
    ) {
      if (
        ingameMessage?.red_status === "matching_successful" ||
        ingameMessage?.blue_status === "matching_successful"
      ) {
        toast.info("The match has been canceled", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 1000,
          toastId: "match-cancel",
        });
        return;
      }

      // if (youReady) {
      //   toast.info("The opponent did not build their lineup", {
      //     style: { maxWidth: 337, height: 40, borderRadius: 8 },
      //     autoClose: 1000,
      //     toastId: "op-not-build-lineup",
      //   });
      // } else {
      //   toast.info("You did not build your lineup", {
      //     style: { maxWidth: 337, height: 40, borderRadius: 8 },
      //     autoClose: 1000,
      //     toastId: "you-not-build-lineup",
      //   });
      // }
      // setTimeout(() => {
      //   handleBackButton();
      // }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingameMessage]);

  // const countTurn = useRef(0);
  const firstHit = useRef(true);

  const [attackResponse, setAttackResponse] = useState<any>();

  useEffect(() => {
    if (!attackResponse) {
      return;
    }

    if (!attackResponse?.attack) {
      return;
    }

    const attackResult = Object.values(attackResponse?.attack)[0];

    if (attackResult && yourTurn) {
      if (firstHit.current) {
        toast.info("Hit! Keep shooting!", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 500,
          toastId: "you-hit",
        });
      }
      firstHit.current = false;
    }
    if (!attackResult) {
      firstHit.current = true;
      // countTurn.current = countTurn.current + 1;
      // if (countTurn.current === 2) {
      //   setTurn((prev) => prev + 1);
      //   countTurn.current = 0;
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attackResponse]);

  const skipCount =
    accountV2?.data?.id === match?.current_match?.red_user_id
      ? ingameMessage?.red_skip_turn
      : ingameMessage?.blue_skip_turn;

  const allPlayerAccepted =
    ingameMessage?.red_status === "accepted" &&
    ingameMessage?.blue_status === "accepted";

  const allPlayerReady =
    ingameMessage?.red_status === "build_lineup_done" &&
    ingameMessage?.blue_status === "build_lineup_done";

  const buildLineupStep =
    ingameMessage?.red_status === "build_lineup_done" ||
    ingameMessage?.blue_status === "build_lineup_done";

  const allPlayerMatchingSuccessful =
    message?.data?.current_match?.red_status === "matching_successful" &&
    message?.data?.current_match?.blue_status === "matching_successful";

  // useEffect(() => {
  //   if (allPlayerAccepted) {
  //     tele.BackButton.hide();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allPlayerAccepted]);

  const [rematch, setRematch] = useState<boolean>(false);

  useEffect(() => {
    if (allPlayerReady) {
      if (rematch) {
        return;
      }
      // handleChangeSocketAttackUrl();
      setShowPickTurn(1);
      // gameCountDownRef.current?.restartFromParent();
      // setReady(true);
      // setTurn((prev) => prev + 1);
      // handleChangeSocketAttackUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlayerReady]);

  const yourTurn = useBirdBattle((state: any) => state.yourTurn);
  const updateYourTurn = useBirdBattle((state: any) => state.updateYourTurn);

  useEffect(() => {
    if (accountV2?.data?.id && ingameMessage?.is_turn) {
      updateYourTurn(accountV2?.data?.id === ingameMessage?.is_turn);
    }
  }, [accountV2?.data?.id, ingameMessage?.is_turn, updateYourTurn]);

  const [attacking, setAttacking] = useState<boolean>(false);
  const handleAttack = (attackPosition: string) => {
    setAttacking(true);
    const params = [match?.current_match_id, attackPosition];
    ws.attack(Date.now(), params)
      .then((response) => {
        const message = response?.message;
        if (message) {
          if (message === "position attacked") {
            setAttacking(false);
            return;
          }
          toast.error(message);
        }
        if (response?.data?.attack) {
          // setInGameMessage(response?.data?.match);
          // setAttackResponse({
          //   ...response?.data?.match,
          //   attack: response?.data?.attack,
          // });
          updateYourTurn(
            accountV2?.data?.id === response?.data?.match?.is_turn,
          );

          if (response?.data?.winner && response?.data?.loser) {
            updateEndgame({
              winner: response?.data?.winner,
              loser: response?.data?.loser,
              ...response?.data,
            });
            // handleCloseSocket();
            updateRandomHitExclude([]);
            updateMatch(null);
            updateLineup([]);
            navigate(navPaths.END_GAME);
          }
        }
        setAttacking(false);
      })
      .catch((error) => {
        console.error("handleAttack failed:", error);
        setAttacking(false);
      })
      .finally(() => setAttacking(false));
  };

  const updateUsers = useBirdBattle((state: any) => state.updateUsers);

  const handleForfeit = (matchId?: string) => {
    const params = [matchId ? matchId : retreatId];
    ws.forfeit(Date.now(), params)
      .then(() => {
        updateEndgame({
          winner: "",
          loser: accountV2?.data?.id,
          opponentRetreat: false,
        });
        // handleCloseSocket();
        updateRandomHitExclude([]);
        updateMatch(null);
        updateUsers([]);
        updateLineup([]);
        navigate(navPaths.END_GAME);
      })
      .catch((error) => {
        console.error("handleForfeit failed:", error);
      });
  };

  const handleCurrentState = (forfeit: boolean) => {
    ws.currentState(Date.now())
      .then((response: any) => {
        const matchId = response?.data?.current_match_id;
        if (!matchId) {
          return;
        }
        forfeit && handleForfeit(matchId);

        // updateMatch(matchData);
      })
      .catch((error: any) => {
        console.error("Error fetching current state:", error);
      });
  };

  useEffect(() => {
    if (!matchMaking) {
      handleCurrentState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchMaking]);

  // const handleUnsubscribe = () => {
  //   ws.unsubscribe({
  //     id: id,
  //     method: "unsubscribe",
  //     params: [`match:${match?.current_match_id}`],
  //   })
  //     .then(() => {
  //       console.log("Unsubscribed successfully");
  //       setId((prev) => prev + 1);
  //     })
  //     .catch((error) => {
  //       console.error("Unsubscribe failed:", error);
  //     });
  // };

  const users = useBirdBattle((state: any) => state.users);

  const opponentName = users?.find(
    (user: any) => user?.userId !== accountV2?.data?.id,
  )?.name;

  const handleCancelMatchmaking = () => {
    ws.cancelMatchmaking(Date.now())
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const [matchFound, setMatchFound] = useState<boolean>(false);

  useEffect(() => {
    if (ingameMessage?.step) {
      setTurn(ingameMessage?.step);
    }
  }, [ingameMessage?.step]);

  const onEndCountDown = (lineup: any) => {
    handleBuildLineup(lineup);
  };

  const [reconnecting, setReconnecting] = useState<boolean>(false);

  const ReconnectingLoader = () => {
    if (!reconnecting) return null;
    return (
      <div className="absolute inset-0 backdrop-blur-[2px] z-50 bg-[#000000CC] flex flex-col items-center justify-center gap-4">
        <div className="game-loader-2"></div>
        <div className="text-white font-baloo-paaji font-bold text-[24px]">
          Connecting
        </div>
      </div>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
        // containerId="battle-seed"
      />
      {!buildLineupStep && !playWithFrens && (
        <MatchFound
          matchFound={matchFound}
          setMatchFound={setMatchFound}
          allPlayerAccepted={allPlayerAccepted}
          allPlayerMatchingSuccessful={allPlayerMatchingSuccessful}
          ws={ws}
          message={ingameMessage}
        />
      )}
      <ReconnectingLoader />
      <div
        // onClick={() => handleCurrentState(true)}
        className={clsx(
          "py-[20px] px-5 bg-[#F2FFE0] h-screen z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji absolute inset-0 transition-all ease-linear",
          matchMaking ? "translate-y-0" : "translate-y-[100%]",
        )}
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        <PublicMatchMaking
          setMatchMaking={setMatchMaking}
          ws={ws}
          setInGameMessage={setInGameMessage}
          setYouReady={setYouReady}
          setAttackResponse={setAttackResponse}
          setMessage={setMessage}
          setReady={setReady}
          setTurn={setTurn}
          setMatchFound={setMatchFound}
          allPlayerAccepted={allPlayerAccepted}
          setRematch={setRematch}
          setReconnecting={setReconnecting}
        />
      </div>
      <PickTurn
        pickTurn={showPickTurn}
        setShowPickTurn={setShowPickTurn}
        data={ingameMessage}
        accountId={accountV2?.data?.id || ""}
      />
      {!matchMaking && (
        <div
          className="px-4 text-white bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji flex flex-col items-center justify-center max-w-md mx-auto"
          style={{
            backgroundImage: `url("/images/battle_bird/background.png")`,
          }}
        >
          {attacking && <Loader />}
          <div className="w-screen h-screen backdrop-blur-md bg-[#00000066] absolute z-0 top-0 left-0 max-w-md mx-auto"></div>
          <div className="z-20 flex flex-col items-center justify-center gap-1">
            <span className="font-baloo-paaji text-baloo-border font-black text-[40px] text-white uppercase">
              {turn > 0 ? `Round ${turn}` : "Lineup Build"}
            </span>
            <CustomCountdown
              gameReady={ready}
              youReady={youReady}
              yourTurn={yourTurn}
              onEndCountDown={onEndCountDown}
              opponentName={opponentName}
              timestamp={ingameMessage?.timestamp_turn}
              lineup={lineup}
            />
            {/* <GameCountDown
                ref={gameCountDownRef}
                gameReady={ready}
                yourTurn={yourTurn}
                onCompleteTime={onCompleteTime}
                opponentName={opponentName}
                timestampDeploy={message?.data?.current_match?.timestamp_turn}
                timestampTurn={ingameMessage?.timestamp_turn}
                turn={turn}
              /> */}
          </div>

          <GameMap10x10
            ref={gameMapRef}
            ready={ready}
            yourTurn={yourTurn}
            // handleSendMessage={handleSendMessage}
            // lastMessage={lastMessage}
            handleSendMessage={() => {}}
            lastMessage={null}
            accountId={accountV2?.data?.id || ""}
            handleAttack={handleAttack}
            attackResponse={ingameMessage}
            youReady={youReady}
            rematch={rematch}
          />

          <div
            className={clsx(
              "w-full z-10 px-[14px] flex items-center justify-center",
              ready ? "invisible" : "visible",
              isMobile ? "mt-5" : "mt-2",
            )}
          >
            <div className="flex items-center gap-2">
              {isMobile ? (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 10.9746C20 11.2611 19.8958 11.4954 19.6875 11.6777L16.5625 14.4902C16.3802 14.6465 16.1719 14.7246 15.9375 14.7246C15.8073 14.7246 15.6771 14.6986 15.5469 14.6465C15.2083 14.4642 15.026 14.1777 15 13.7871V12.2246H11.25V15.9746H12.8125C13.2031 16.0007 13.4896 16.1829 13.6719 16.5215C13.8281 16.8861 13.776 17.2246 13.5156 17.5371L10.7031 20.6621C10.5208 20.8704 10.2734 20.9746 9.96094 20.9746C9.67448 20.9746 9.4401 20.8704 9.25781 20.6621L6.48438 17.5371C6.22396 17.2246 6.17188 16.8861 6.32812 16.5215C6.51042 16.1829 6.79688 16.0007 7.1875 15.9746H8.71094V12.2246H5V13.7871C4.97396 14.1777 4.79167 14.4642 4.45312 14.6465C4.32292 14.6986 4.19271 14.7246 4.0625 14.7246C3.82812 14.7246 3.61979 14.6465 3.4375 14.4902L0.3125 11.6777C0.104167 11.4954 0 11.2611 0 10.9746C0 10.6882 0.104167 10.4538 0.3125 10.2715L3.4375 7.45898C3.75 7.22461 4.08854 7.17253 4.45312 7.30273C4.79167 7.48503 4.97396 7.77148 5 8.16211V9.72461H8.71094V5.97461H7.1875C6.79688 5.94857 6.51042 5.76628 6.32812 5.42773C6.17188 5.06315 6.22396 4.72461 6.48438 4.41211L9.25781 1.28711C9.46615 1.07878 9.70052 0.974609 9.96094 0.974609C10.2474 0.974609 10.4818 1.07878 10.6641 1.28711L13.4766 4.41211C13.737 4.72461 13.7891 5.06315 13.6328 5.42773C13.4505 5.76628 13.1641 5.94857 12.7734 5.97461H11.2109V9.72461H15V8.16211C15.026 7.77148 15.2083 7.48503 15.5469 7.30273C15.9115 7.17253 16.25 7.22461 16.5625 7.45898L19.6875 10.2715C19.8958 10.4538 20 10.6882 20 10.9746Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <div className="h-5" />
              )}
              <span className="font-semibold text-sm">
                {isMobile ? "Drag & drop your unit on the battlefield" : ""}
              </span>
            </div>
          </div>
          {ready && (
            <div
              className={clsx(
                "text-white z-30 absolute right-0 left-0 flex flex-col items-center justify-center",
                isMobile ? "bottom-[10%]" : "bottom-[5%]",
              )}
            >
              {/* <span className="font-baloo-paaji text-baloo-border font-bold text-[24px]">
                {yourTurn && "Your turn to attack"}
              </span>

              {!yourTurn && (
                <div className="flex items-center">
                  <span className="font-baloo-paaji missed-turn font-bold text-[24px] capitalize">
                    {opponentName}
                  </span>
                  <span className="font-baloo-paaji missed-turn font-bold text-[24px]">
                    's turn to attack
                  </span>
                </div>
              )} */}
              <>
                <span className="text-sm text-center">
                  If you skip <span className="missed-turn">3</span> turns, you
                  will lose
                </span>
                <span className="text-sm">
                  Number of turns skipped:{" "}
                  <span className="missed-turn">{skipCount}</span>
                </span>
              </>

              {ready && (
                <div
                  onClick={handleBackButton}
                  className={clsx(
                    "border-[1.5px] border-white rounded flex items-center justify-center gap-2 px-3 py-[2px] mt-4 -mb-4 cursor-pointer font-medium",
                  )}
                >
                  <span className="mb-[2px]">Leave</span>
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 17.3125C7.5 17.8594 7.07031 18.25 6.5625 18.25H3.75C1.64062 18.25 0 16.6094 0 14.5V4.5C0 2.42969 1.64062 0.75 3.75 0.75H6.5625C7.07031 0.75 7.5 1.17969 7.5 1.6875C7.5 2.23438 7.07031 2.625 6.5625 2.625H3.75C2.69531 2.625 1.875 3.48438 1.875 4.5V14.5C1.875 15.5547 2.69531 16.375 3.75 16.375H6.5625C7.07031 16.375 7.5 16.8047 7.5 17.3125ZM19.7266 8.875L14.7656 3.5625C14.4141 3.17188 13.8281 3.17188 13.4375 3.52344C13.0469 3.875 13.0469 4.46094 13.3984 4.85156L16.875 8.5625H7.14844C6.64062 8.5625 6.25 8.99219 6.25 9.5C6.25 10.0469 6.64062 10.4375 7.14844 10.4375H16.8359L13.3203 14.1875C12.9688 14.5781 12.9688 15.1641 13.3594 15.5156C13.5938 15.6719 13.8281 15.75 14.0234 15.75C14.2578 15.75 14.4922 15.6719 14.6875 15.4766L19.6484 10.1641C20.0781 9.8125 20.0781 9.22656 19.7266 8.875Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
          )}
          <div
            className={clsx(
              "z-10 flex items-center w-full gap-4",
              ready ? "invisible" : "visible",
              isMobile ? "mt-5" : "mt-3 -mb-1",
            )}
          >
            <button
              onClick={() => {}}
              className={clsx(
                "text-[#fff] text-[24px] w-full font-bold uppercase ",
                "border-[2px] border-[#0B8B00] bg-[#00000033] shadow-[0_3.5px_0.5px_#0B8B00] rounded-xl",
                "active:translate-y-[3.5px] active:shadow-none",
              )}
            >
              <div
                onClick={() => {
                  !youReady && gameMapRef.current?.autoArrangeFromParent();
                }}
                className={clsx(
                  "border-2 border-[#78ED4F] rounded-[11px] font-baloo-paaji text-baloo-border",
                  isMobile ? "py-[6px]" : "py-[1px]",
                )}
              >
                Auto
              </div>
            </button>
            <BaseBtn
              className={isMobile ? "py-[6px]" : "py-[1px]"}
              onClick={() => {
                // handleSendMessage(
                //   JSON.stringify({
                //     lineup: lineup,
                //   }),
                // );
                handleBuildLineup(lineup);
              }}
            >
              Ready
            </BaseBtn>
          </div>
          <div
            className={clsx(
              "mt-3 text-[#FFE500] flex items-center justify-center z-10 w-full",
              buildLineupStep && turn < 1 ? "visible" : "invisible",
              isDesktop && "mb-1",
            )}
          >
            {youReady
              ? "Waiting for opponent..."
              : "The opponent is waiting for you."}
          </div>
        </div>
      )}

      <ModalContainer>
        {showRetreatModal && (
          <CreatematchModal
            retreat={handleForfeit}
            handleClose={() => setShowRetreatModal(false)}
            title={"Retreat from game"}
            desciption={
              "If you leave the match, you will lose both the game and your money. Are you sure you want to exit?"
            }
            type="retreat"
          />
        )}
      </ModalContainer>
    </>
  );
};

export default DeployBird;
