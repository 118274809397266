import clsx from "clsx";
import { isBrowser } from "react-device-detect";
import { LoadingButton } from "@mui/lab";
import { useNewComerStore } from "../../../store/newComerStore";
import { useAccount } from "../../../components/Hooks/useRegister";
import { useEffect, useState } from "react";
import useClaimFirstEgg from "../../Home/Hooks/useClaimFirstEgg";

const WelcomeReward = () => {
  const tele = window.Telegram.WebApp;
  const { refetch: refreshAccount, isFetched } = useAccount(false);
  const { mutateAsync: claimFirstEggAsync } = useClaimFirstEgg();

  const newComerData = useNewComerStore((state: any) => state.newComerData);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    isFetched && setLoading(false);
  }, [isFetched]);

  return (
    <div className="flex flex-col pt-8 max-w-md mx-auto items-center bg-[#F2FFE0] min-h-screen h-screen relative">
      <div className="text-[28px] font-extrabold tracking-tight">
        Welcome back home!
      </div>
      <div className="text-[16px] font-[400] tracking-tight">
        SEED reward for OG
      </div>

      <div className="fixed bottom-[20px] z-40 left-4 right-4">
        <div className="text-[16px] font-[500] tracking-tight mb-4 w-full text-center">
          You’re in the Top {newComerData?.top_rate}% Telegram users
        </div>
        <LoadingButton
          loading={loading}
          onClick={async () => {
            refreshAccount();
            await claimFirstEggAsync();
            tele.openTelegramLink("https://t.me/seedupdates");
          }}
          className="btn-hover normal-case w-full flex gap-1 text-[16px] font-extrabold text-white py-[16px] rounded-xl bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
        >
          <span>Join us to get {newComerData?.age} SEED</span>
          <img
            src="/images/icons/token_icon.png"
            className="w-[22px] h-[22px]"
            alt="startup"
          ></img>
        </LoadingButton>
      </div>

      <div className="absolute inset-0 z-0 flex items-center px-4">
        <img
          src="/images/bg-welcome-reward.png"
          className="w-full aspect-square"
          alt="startup"
        ></img>
      </div>
      <div
        className={clsx(
          "absolute inset-0 z-10 flex flex-col items-center justify-center",
          isBrowser ? "gap-[-20px] mb-6" : "gap-2"
        )}
      >
        <span className="text-[240px] font-extrabold leading-[240px]">
          {newComerData?.age}
        </span>
        <span className="text-[37.53px] font-extrabold tracking-tight">
          years ago
        </span>
      </div>
    </div>
  );
};

export default WelcomeReward;
