import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const ProfilePage = lazy(() => import("./Pages/Profile"));

export const profileRoute: RouteObject = {
  path: "",
  children: [{ path: navPaths.PROFILE, Component: ProfilePage }],
};
