import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

// V2
// TODO: Store previous balance into local storage and try to calculate current balance based previous one when API failed to response
export const requestAccountBalance = async (): Promise<number> => {
  const response = await fetchApi("GET", "/profile/balance");
  if (response.error) {
    return 0;
  }

  const balanceResponse: ServerResponse<number> = await response.data.json();
  if (balanceResponse.error) {
    return 0;
  }

  return balanceResponse.data;
};

export const requestClaimSeed = async (): Promise<Result<boolean, string>> => {
  const response = await fetchApi("POST", "/seed/claim");
  if (response.error) {
    return {
      error: "Claim failed",
    };
  }

  const claimResponse: ServerResponse<null> = await response.data.json();
  if (claimResponse.error) {
    return {
      error: claimResponse.error.message,
    };
  }

  return {
    data: true,
  };
};

export const useAccountBalance = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AccountBalance"],
    queryFn: requestAccountBalance,
  });

  return { balance: data ?? 0, isLoading, refetch };
};

export const useClaimSeedV2 = () => {
  const { mutate, mutateAsync, isPending } = useMutation({
    mutationFn: requestClaimSeed,
    retry: false,
  });

  return { claim: mutate, claimAsync: mutateAsync, isPending };
};
