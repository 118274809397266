import { useQuery } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function getBattleRoomType() {
  return await api.get<any>(`/api/v1/battle-seed/room-type/list`);
}

export default function useBattleRoomType() {
  return useQuery({
    queryKey: ["BattleRoomType"],
    queryFn: () => getBattleRoomType(),
  });
}
