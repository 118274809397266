import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const Purchase = lazy(() => import("./Pages/Purchase"));
const OpenBox = lazy(() => import("./Pages/OpenBox"));
const OpenMultiBox = lazy(() => import("./Pages/OpenMultiBox"));

export const purchaseRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.PURCHASE, Component: Purchase },
    { path: navPaths.OPEN_PURCHASE_BOX, Component: OpenBox },
    { path: navPaths.OPEN_PURCHASED_MULTI_BOX, Component: OpenMultiBox },
  ],
};
