import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const ListGame = lazy(() => import("./Games/ListGame"));

export const gamesRoute: RouteObject = {
  path: navPaths.GAME_LIST,
  Component: ListGame,
};
