import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// const initialData = {
//   newcomer_event: true,
//   newcomer_event_boost_duration: 100,
//   age: 5,
//   top_rate: 20,
//   bonus_og: 5000000000,
//   bonus_premium: 5000000000,
// };

export const useNewComerStore = create(
  persist(
    (set) => ({
      newComerData: undefined,
      updateNewComerData: (newComerData: any) =>
        set({ newComerData: newComerData }),
    }),
    {
      name: "newcomer-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
