import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const SpinEgg = lazy(() => import("./Pages/SpinEgg"));

export const spinEggRoute: RouteObject = {
  path: navPaths.SPIN_EGG,
  Component: SpinEgg,
};
