import clsx from "clsx";
import { MotionValue, motion } from "framer-motion";
import { ReactNode } from "react";

const Backdrop = ({
  children,
  onClick,
  className,
}: {
  children: ReactNode | MotionValue<number> | MotionValue<string>;
  onClick: any;
  className?: string;
}) => {
  return (
    <motion.div
      className={clsx("backdrop", className)}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
