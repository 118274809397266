import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useChangeTheme = create(
  persist(
    (set) => ({
      theme: "",
      setTheme: (theme: any) => set({ theme: theme }),
    }),
    {
      name: "theme-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
