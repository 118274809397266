import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const GuildWelcome = lazy(() => import("./Pages/GuildWelcome"));
const GuildList = lazy(() => import("./Pages/GuildList"));
const GuildDetails = lazy(() => import("./Pages/GuildDetails"));
const GuildMessage = lazy(() => import("./Pages/GuildMessage"));

export const guildRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.GUILD_WELCOME, Component: GuildWelcome },
    { path: navPaths.GUILD_LIST, Component: GuildList },
    {
      path: navPaths.GUILD_DETAILS,
      Component: GuildDetails,
    },
    { path: navPaths.GUILD_MESSAGE, Component: GuildMessage },
  ],
};
