import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const Market = lazy(() => import("./Pages/Market"));
const ItemDetail = lazy(() => import("./Components/ItemDetail"));

export const marketRoute: RouteObject = {
  path: navPaths.MARKET,
  Component: Market,
};

export const marketItemRoute: RouteObject = {
  path: navPaths.MARKET_ITEM,
  Component: ItemDetail,
};
