import clsx from "clsx";
import { motion } from "framer-motion";
import { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Backdrop from "../../../../components/common/Backdrop";
import { dropIn } from "../../../../components/common/ModalContainer";
import { navPaths } from "../../../../constants/navbar.constants";
import { useBirdBattle } from "../../../../store/useBirdBattle";
import BaseBtn from "../BaseBtn";
import SecondaryBtn from "../SecondaryBtn";

const JoinRoomModal = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const joinRoomCode = useBirdBattle((state: any) => state.joinRoomCode);
  const setJoinRoomCode = useBirdBattle((state: any) => state.setJoinRoomCode);

  const handleInputCode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (value.length > 6) {
      return;
    }
    const formatedValue = value.replace(/\D+/g, "");
    setJoinRoomCode(formatedValue);
  };

  const handleJoinRoom = () => {
    navigate(navPaths.DEPLOY_BIRD);
  };

  return (
    <Backdrop onClick={() => handleClose()} className="!bg-[#000000CC]">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFFFFF] border-[6px] border-[#39494b] rounded-[20px] py-[23px] px-5 flex flex-col items-center justify-center relative ",
          )}
        >
          <>
            <p className="text-[#021B00] text-[28px] font-baloo-paaji font-black">
              Join Room
            </p>
            <p className="text-[#000000] mt-3 mb-2 font-semibold">
              Enter Room Code
            </p>
            <input
              value={joinRoomCode}
              inputMode="numeric"
              id="join-room-code-input"
              placeholder="Enter code"
              className={clsx(
                "w-full h-[55px] outline-none border-[1.5px] border-[#021B00] rounded-xl text-center text-[32px] font-extrabold pb-1",
              )}
              onChange={handleInputCode}
            ></input>
          </>
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            <SecondaryBtn
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </SecondaryBtn>
            <BaseBtn
              onClick={() => {
                if (joinRoomCode?.length < 4) {
                  toast.error("Invalid room code", {
                    style: { maxWidth: 337, height: 40, borderRadius: 8 },
                    autoClose: 2000,
                    toastId: "join-room",
                  });
                  return;
                }
                handleJoinRoom();
                handleClose();
              }}
            >
              Join
            </BaseBtn>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default JoinRoomModal;
