import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../config/api";
import { Result } from "../../types/utils";
import { ServerResponse } from "../../types/api";

export const createAcount = async (): Promise<Result<any, string>> => {
  const response = await fetchApi("POST", `/profile`);
  if (response.error) {
    return {
      error: response.error.message,
    };
  }

  const registerResponse: ServerResponse<null> = await response.data.json();
  if (registerResponse.error) {
    return {
      error: registerResponse.error.message,
    };
  }

  return {
    data: registerResponse?.data,
  };
};

export default function useCreateAcount() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["createAcount"],
    mutationFn: createAcount,
  });

  return { mutateAsync, isPending };
}
