import { Backdrop, CircularProgress } from "@mui/material";
import { Key } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { navPaths } from "../../../constants/navbar.constants";
import "../BattleBird.css";
import PlayModeItem from "../Components/PlayModeItem";
import useBattleRoomType from "../Hooks/useBattleRoomType";

const PlayOnlineScreen = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate(navPaths.BATTLE_HOME));

  const { data, isLoading } = useBattleRoomType();

  const playModes = data?.data?.data || [];

  // const playModes = [
  //   {
  //     title: "Tokyo Neon Hall",
  //     size: "5x5",
  //     online: 120,
  //     fee: 1000,
  //     reward: ["seed", "common", "uncommon"],
  //     roomTypeId: "11689874-d780-44b4-b859-077764e9bc89",
  //   },
  //   {
  //     title: "Las vegas Full House",
  //     size: "10x10",
  //     online: 120,
  //     fee: 1000,
  //     reward: ["chest", "epic", "legendary"],
  //     roomTypeId: "11689874-d780-44b4-b859-077764e9bc89",
  //   },
  //   {
  //     title: "SYDNEY Maria Bar",
  //     size: "8x8",
  //     online: 120,
  //     fee: 1000,
  //     reward: ["seed2", "rare", "epic"],
  //     roomTypeId: "11689874-d780-44b4-b859-077764e9bc89",
  //   },
  // ];
  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji"
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-sm bg-[#00000033] absolute z-0 top-0 left-0"></div>
        <div className="absolute h-[30%] -left-5 -right-5  top-0 bg-gradient-to-b from-[#000000E5] to-transparent z-20 pointer-events-none"></div>
        <div className="absolute h-[30%] -left-5 -right-5  bottom-0 bg-gradient-to-t from-[#000000E5] to-transparent z-20 pointer-events-none"></div>
        <div className="relative z-10 h-full ">
          {/* <Swiper
            direction={"vertical"}
            // rewind
            slidesPerView={3}
            centeredSlides
            spaceBetween={20}
            className="mySwiper bg-white/50 p-5"
            initialSlide={1}
            breakpoints={{
              "@0.00": {
                slidesPerView: 3,
              },
              "@0.50": {
                slidesPerView: 2,
              },
              "@1.00": {
                slidesPerView: 1,
              },
            }}
          >
            {playModes.map((item) => {
              return (
                <SwiperSlide className="w-full h-fit">
                  {({ isActive }) => (
                    <div className="flex justify-center">
                      <PlayModeItem item={item} isActive={isActive} />
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper> */}
          {isLoading && (
            <div className="game-loader absolute inset-0 mx-auto my-auto"></div>
          )}
          {!isLoading && (
            <Swiper
              direction={"vertical"}
              slidesPerView={"auto"}
              spaceBetween={20}
              centeredSlides={true}
              className="mySwiper py-5 px-4"
              initialSlide={0}
              // breakpoints={{
              //   "@0.00": {
              //     slidesPerView: 3,
              //   },
              //   "@0.50": {
              //     slidesPerView: 2,
              //   },
              //   "@1.00": {
              //     slidesPerView: 1,
              //   },
              // }}
            >
              {playModes?.map((item: any, index: Key | null | undefined) => {
                return (
                  <SwiperSlide key={index} className="w-full h-fit">
                    {({ isActive }) => (
                      <PlayModeItem
                        item={item}
                        isActive={isActive}
                        index={index as number}
                      />
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default PlayOnlineScreen;
