import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import ModalContainer from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import BaseBtn from "../Components/BaseBtn";
import CreatematchModal from "../Components/Modal/CreateMatchModal";
import JoinRoomModal from "../Components/Modal/JoinRoomModal";
import { useBirdBattle } from "../../../store/useBirdBattle";
import clsx from "clsx";

const HomeScreen = () => {
  const navigate = useNavigate();
  const setPlayWithFrens = useBirdBattle(
    (state: any) => state.setPlayWithFrens,
  );
  const setJoinRoomCode = useBirdBattle((state: any) => state.setJoinRoomCode);

  // console.log("currentState", currentState, match);

  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate(navPaths.HOME));

  const [showCreatematchModal, setShowCreatematchModal] =
    useState<boolean>(false);
  const [isOpenJoinModal, setIsOpenJoinModal] = useState<boolean>(false);

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="pt-[42px] px-7 bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji"
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-sm bg-[#00000036] absolute z-0 top-0 left-0 max-w-md mx-auto"></div>
        <div
          className={clsx(
            "relative z-10 h-full flex flex-col justify-center gap-[45px]",
            isDesktop ? "pb-[60%]" : "pb-[30%]",
          )}
        >
          <img
            src="/images/battle_bird/logo.png"
            alt=""
            className={clsx(
              "object-contain w-full h-fit",
              isDesktop ? "-mb-[100px]" : "-mb-[50px]",
            )}
          />
          <div className="flex flex-col w-full gap-8">
            <BaseBtn
              onClick={() => {
                setPlayWithFrens(false);
                navigate(navPaths.BATTLE_ONLINE);
              }}
            >
              Play Online
            </BaseBtn>
            <BaseBtn
              onClick={() => {
                setPlayWithFrens(true);
                setShowCreatematchModal(true);
                setJoinRoomCode("");
              }}
            >
              Play With Friends
            </BaseBtn>
            {/* <button
              // onClick={() => setShowCreatematchModal(true)}
              onClick={() => {
                toast.info("Coming soon", {
                  style: { maxWidth: 337, height: 40, borderRadius: 8 },
                  autoClose: 1000,
                  toastId: "coming-soon-game",
                });
              }}
              className="bg-[#B3B3B3] uppercase border-[2.5px] rounded-xl py-2 border-[#606060] shadow-[0_3.5px_0.5px_#5F5F5F] font-baloo-paaji font-bold text-[24px] text-[#515151] pointer-events-none"
            >
              Play With Friends
            </button> */}

            {/* <BaseBtn onClick={() => navigate(navPaths.PICK_TURN)}>
              Bird Setup
            </BaseBtn> */}
            <div
              onClick={() =>
                tele.openLink(
                  "https://seeddao.gitbook.io/seed-tutorial/seed-arcade/battle-seed",
                )
              }
              className="text-white font-semibold text-center cursor-pointer relative [text-shadow:_1px_1px_1px_rgb(0_0_0_/_50%)] underline"
            >
              How to play?
              {/* <div className=" absolute right-0 left-0 bottom-0 mx-auto h-[1.5px] w-[100px] bg-white drop-shadow-md" /> */}
            </div>
          </div>
        </div>
        {isDesktop && (
          <div className="bg-[#00000060] py-[10px] right-7 left-7 bottom-7 text-center absolute text-white font-medium rounded-lg">
            For the best experience, play on the
            <br />
            mobile app!
          </div>
        )}
      </div>
      <ModalContainer>
        {showCreatematchModal && (
          <CreatematchModal
            handleClose={() => setShowCreatematchModal(false)}
            openJoinModal={() => setIsOpenJoinModal(true)}
            title={"Play with friend"}
            desciption={"What would you like to do?"}
            type="create"
          />
        )}
      </ModalContainer>
      <ModalContainer>
        {isOpenJoinModal && (
          <JoinRoomModal handleClose={() => setIsOpenJoinModal(false)} />
        )}
      </ModalContainer>
    </>
  );
};

export default HomeScreen;
