import { animated, useSpring, useSpringRef } from "@react-spring/web";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useBattleTut } from "../../../../store/useBattleTut";

const CustomCountdown = ({
  gameReady,
  youReady,
  yourTurn,
  onEndCountDown,
  opponentName,
  timestamp,
  lineup,
}: {
  gameReady: boolean;
  youReady: boolean;
  yourTurn: boolean;
  onEndCountDown: (arg0: any) => void;
  opponentName: string;
  timestamp: string;
  lineup: any;
}) => {
  const firstTime = useBattleTut((state: any) => state.firstTime);

  const calculateTimeLeft: any = () => {
    const difference = gameReady
      ? +new Date(timestamp) - +new Date()
      : +new Date(timestamp) - 2000 - +new Date();
    let seconds;
    if (difference > 0) {
      seconds = Math.floor((difference / 1000) % 60);
    }
    return seconds;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeLeft());

  useEffect(() => {
    if (youReady || gameReady) {
      return;
    }
    if (timeRemaining === 0) {
      onEndCountDown(lineup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, lineup, youReady, gameReady]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime: any) => {
        if (prevTime === 0) {
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countdownRef = useSpringRef();
  const countdownAnim = useSpring({
    ref: countdownRef,
    from: {
      opacity: 0,
      y: -200,
    },
    to: {
      opacity: 1,
      y: 9,
    },
    reset: true,
    config: { tension: 180, friction: 15 },
    // onResolve: () => {
    //   countdownRef2.start();
    // },
  });

  // const countdownAnim2 = useSpring({
  //   from: {
  //     scale: 1,
  //   },
  //   to: [
  //     {
  //       scale: 1.02,
  //     },
  //     {
  //       scale: 1,
  //     },
  //   ],
  //   // reverse: true,
  //   loop: true,
  //   config: { duration: 500, tension: 180, friction: 15 },
  // });

  useEffect(() => {
    setTimeRemaining(calculateTimeLeft());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, gameReady]);

  useEffect(() => {
    countdownRef.start();
  }, [countdownRef, yourTurn, gameReady]);

  return (
    <animated.div
      style={countdownAnim}
      className={clsx(
        "border-2 rounded-lg flex items-center justify-center px-3",
        // yourTurn ? "border-white/50" : "border-[#ff8b8b]",
        "border-white/50",
        isMobile ? "mb-8 py-[6px]" : "mb-6 py-1 -mt-3",
      )}
    >
      <div className="text-base mb-[2px]">
        <span>{!gameReady && "Time Remaining"}</span>
        <span>{gameReady && yourTurn && "Your Turn"}</span>

        {gameReady && !yourTurn && (
          <>
            <span className="capitalize">{opponentName}</span>
            <span>'s Turn</span>
          </>
        )}

        {!gameReady && firstTime && !Number.isNaN(timeRemaining) && (
          <>
            {timeRemaining ? (
              <span>
                : {timeRemaining >= 10 ? timeRemaining : "0" + timeRemaining}s
              </span>
            ) : (
              ": 00s"
            )}
          </>
        )}
        {!firstTime && !Number.isNaN(timeRemaining) && (
          <>
            {timeRemaining ? (
              <span>
                : {timeRemaining >= 10 ? timeRemaining : "0" + timeRemaining}s
              </span>
            ) : (
              ": 00s"
            )}
          </>
        )}
      </div>
    </animated.div>
  );
};

export default CustomCountdown;
