import { AnimatePresence } from "framer-motion";
import { ReactNode } from "react";

export const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "tween",
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
    duration: 0.3,
  },
  inputing: {
    y: "-30%",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "tween",
    },
  },
  hiddenHistory: {
    y: "100vh",
    opacity: 0,
  },
};

const ModalContainer = ({ children }: { children: ReactNode }) => (
  // Enables the animation of components that have been removed from the tree
  <AnimatePresence
    // Disable any initial animations on children that
    // are present when the component is first rendered
    initial={false}
    // Only render one component at a time.
    // The exiting component will finish its exit
    // animation before entering component is rendered
    mode="wait"
    // Fires when all exiting nodes have completed animating out
    //   onExitComplete={() => framerLogger(label)}
  >
    {children}
  </AnimatePresence>
);

export default ModalContainer;
