import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useMissionStore = create(
  persist(
    (set) => ({
      hasMission: false,
      setHasMission: (hasMission: boolean) => set({ hasMission: hasMission }),
    }),
    {
      name: "hasMission-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
