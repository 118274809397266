import clsx from "clsx";
import { useEffect, useState } from "react";
import { useBirdBattle } from "../../../store/useBirdBattle";
import { sleep } from "../../../utils/time";

const PickTurn = ({
  pickTurn,
  setShowPickTurn,
  data,
  accountId,
}: {
  pickTurn: number;
  setShowPickTurn: any;
  data: any;
  accountId: string;
}) => {
  const [picking, setPicking] = useState<number>(0);
  const match = useBirdBattle((state: any) => state.match);
  const users = useBirdBattle((state: any) => state.users);

  const redName = users?.find(
    (user: any) => user?.userId === match?.current_match?.red_user_id,
  )?.name;

  const blueName = users?.find(
    (user: any) => user?.userId === match?.current_match?.blue_user_id,
  )?.name;

  useEffect(() => {
    if (pickTurn === 1) {
      handleFakePick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickTurn]);

  const handleFakePick = async () => {
    await sleep(700);
    setPicking(1);
    await sleep(3000);
    setPicking(2);
    await sleep(1000);
    setShowPickTurn(2);
  };

  const [youGoFirst, setYouGoFirst] = useState<boolean>(false);

  useEffect(() => {
    if (accountId && data?.is_turn) {
      setYouGoFirst(accountId === data?.is_turn);
    }
  }, [accountId, data?.is_turn]);

  const [userGoFirst, setUserGoFirst] = useState<string>("");

  useEffect(() => {
    if (!match?.current_match?.red_user_id || !data?.is_turn) {
      return;
    }
    if (match?.current_match?.red_user_id === data?.is_turn) {
      setUserGoFirst(redName);
    } else {
      setUserGoFirst(blueName);
    }
  }, [blueName, data?.is_turn, match?.current_match?.red_user_id, redName]);

  return (
    <div
      className={clsx(
        "px-4 text-white bg-[#F2FFE0] h-screen z-50 bg-cover bg-no-repeat bg-center font-baloo-paaji flex flex-col items-center justify-center absolute inset-0 transition-all ease-linear",
        pickTurn === 1 ? "translate-y-0" : "translate-y-[100%]",
      )}
      style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
    >
      {/* <div className="w-screen h-screen backdrop-blur-md bg-[#00000066] absolute z-0 top-0 left-0"></div> */}
      <div className="z-10">
        {picking !== 2 && (
          <div className="w-[120px] aspect-square rounded-full border-[7px] border-[#2EDD03] overflow-hidden relative">
            <img
              src={`https://konsen-image-proxy.taskfi.io/proxy/${redName}`}
              alt="player1"
              className={clsx(
                "w-full aspect-square absolute pickturn-img rounded-full",
              )}
            />
            <img
              src={`https://konsen-image-proxy.taskfi.io/proxy/${blueName}`}
              alt="player1"
              className={clsx(
                "w-full aspect-square absolute pickturn-img animate-delay-[300ms] rounded-full",
                picking === 1 ? "visible" : "invisible",
              )}
            />
          </div>
        )}

        {picking === 2 && (
          <div className="absolute z-20 inset-0 pick-success flex flex-col items-center justify-center gap-2">
            <div className="w-[120px] aspect-square rounded-full border-[7px] border-[#2EDD03] overflow-hidden">
              <img
                src={`https://konsen-image-proxy.taskfi.io/proxy/${userGoFirst}`}
                alt="player1"
                className={clsx("w-full aspect-square scale-125")}
              />
            </div>

            {/* Swap check who goes first */}
            <div className="font-baloo-paaji text-baloo-border font-bold text-[24px] text-white z-30">
              {youGoFirst && "You go first"}
              {!youGoFirst && "The opponent goes first"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PickTurn;
