import clsx from "clsx";
import { motion } from "framer-motion";
import Backdrop from "../../../../components/common/Backdrop";
import { dropIn } from "../../../../components/common/ModalContainer";
import BaseBtn from "../BaseBtn";
import SecondaryBtn from "../SecondaryBtn";

const ConfirmLeaveRoomModal = ({
  handleClose,
  handleLeaveRoom,
}: {
  handleClose: () => void;
  handleLeaveRoom: () => void;
}) => {
  return (
    <Backdrop onClick={() => handleClose()} className="!bg-[#000000E5]">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFFFFF] border-[6px] border-[#FFFFFF33] rounded-[20px] py-[23px] px-5 flex flex-col items-center justify-center relative "
          )}
        >
          <>
            <p className="text-[#021B00] text-[28px] font-baloo-paaji font-black">
              Leave Room
            </p>
            <p className="text-[#4C544C] mt-3">
              Do you want to leave the room?
            </p>
          </>
          <div className="flex justify-center items-center gap-4 mt-6 w-full">
            <SecondaryBtn onClick={() => handleLeaveRoom()}>Yes</SecondaryBtn>
            <BaseBtn onClick={() => handleClose()}>Cancel</BaseBtn>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ConfirmLeaveRoomModal;
