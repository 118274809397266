import { useQuery } from "@tanstack/react-query";
import { api, fetchApi } from "../../../config/api";
import { Task, TaskUser } from "../../../types/task";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export async function getMissions() {
  return await api.get<any>(`/api/v1/tasks/progresses`);
}

export default function useGetMissions() {
  return useQuery({
    queryKey: ["Missions"],
    queryFn: () => getMissions(),
  });
}

// V2
export const requestMissions = async (): Promise<
  Result<Array<Task & { task_user: TaskUser }>, ServerError>
> => {
  const response = await fetchApi("GET", "/tasks/progresses");
  if (response.error) {
    return {
      error: {
        code: "network",
        message: response.error.message,
      },
    };
  }

  const taskResponse: ServerResponse<Array<Task & { task_user: TaskUser }>> =
    await response.data.json();
  if (taskResponse.error) {
    return {
      data: [],
    };
  }

  return {
    data: taskResponse.data,
  };
};

export const useMissions = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["Missions"],
    queryFn: requestMissions,
    retry: 1,
  });

  return { missions: data, isLoading, refetch };
};
