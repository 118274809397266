import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { CreateAccountV2 } from "./Pages/CreateAccountV2";
import { lazy } from "react";

// import CreatePage from "./Pages/CreateAcount";

const InactivePage = lazy(() => import("./Pages/Inactive"));

export const registerRoute: RouteObject = {
  path: navPaths.REGISTER,
  // Component: CreatePage,
  Component: CreateAccountV2,
};

export const InactiveRoute = {
  path: navPaths.INACTIVE,
  Component: InactivePage,
};
