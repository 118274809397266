import BigNumber from "bignumber.js";

export const formatNumberFloatFix = (value: number | string, fix: number) => {
  if (!value) return "0";
  return Number(value).toFixed(fix);
  // .replace(/[.,]0$|[.,]00$|[.,]000$|[.,]0000$|0$|00$|000$|0000$/, "");
};

export const formatDecimals = (value: number | string) => {
  const result = Number(value) / Math.pow(10, 9);
  return result;
};

export const getNumberFormatUs = (
  number: number | string | undefined | null,
  fix?: number,
) => {
  if (typeof number !== "number" && typeof number !== "string") return "0";
  const numberTypeNumb = typeof number === "string" ? Number(number) : number;
  const numberFormatFloat = formatNumberFloatFix(numberTypeNumb, fix ? fix : 2);
  return Number(numberFormatFloat)?.toLocaleString("en-US", {
    maximumFractionDigits: fix ? fix : 2,
  });
};

export const convertNumber = (number: number) => {
  const thresholds = [100, 500, 1000, 5000, 10000, 100000];
  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (number >= thresholds[i]) {
      return thresholds[i] + "+";
    }
  }
  return number.toString();
};

export const formatNumberUS = (value: number | string) => {
  return typeof value === "number"
    ? value.toLocaleString("en-US")
    : (+value).toLocaleString("en-US");
};

export function formatCompactNumber(value: number | string) {
  const valueToNumber = Number(value);
  const billionsAmount = valueToNumber / 1000000000;
  if (billionsAmount >= 1) return formatNumberUS(billionsAmount) + "B";
  const millionsAmount = valueToNumber / 1000000;
  if (millionsAmount >= 1) return formatNumberUS(millionsAmount) + "M";
  const thousandsAmount = valueToNumber / 1000;
  if (thousandsAmount >= 1) return formatNumberUS(thousandsAmount) + "K";
  return formatNumberUS(formatNumberFloatFix(valueToNumber, 2));
}

export const convertUuidToInt = (id: string) => {
  // remove the dashes from the given uuid and convert to a hexadecimal BigNumber object
  const indexInt = BigInt(`0x${id.replace(/-/g, "")}`); // return the decimal representation of the BigNumber object as a string
  return indexInt;
};

export const convertIntToUuid = (num: number | string) => {
  // convert the string representation of the decimal number to a BigNumber object
  const bn = new BigNumber(num, 10);
  // convert the BigNumber to a hexadecimal string
  const id = bn.toString(16);
  // return the string with the dashes (8-4-4-4-12)
  return `${id.substr(0, 8)}-${id.substr(8, 4)}-${id.substr(12, 4)}-${id.substr(
    16,
    4,
  )}-${id.substr(20)}`;
};
