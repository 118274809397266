import { useState } from "react";
import { CalculatePoint } from "../Components/CalculatePoint";
import { AnimatePresence } from "framer-motion";
import WelcomeReward from "../Components/WelcomeReward";

export const CreateAccountV2 = () => {
  const [tab, setTab] = useState<number>(1);

  return (
    <div className="fixed inset-0">
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        mode="wait"
        // Fires when all exiting nodes have completed animating out
        //   onExitComplete={() => framerLogger(label)}
      >
        {tab === 1 && <CalculatePoint setTab={setTab} />}
        {tab === 2 && <WelcomeReward />}
      </AnimatePresence>
    </div>
  );
};
