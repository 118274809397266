import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../config/api";
import { ServerError, ServerResponse } from "../../types/api";
import { Account } from "../../types/user";
import { Result } from "../../types/utils";

// V2

export const requestAccountDetails = async (): Promise<
  Result<Account, ServerError>
> => {
  const response = await fetchApi("GET", "/profile");
  if (!response?.data?.ok) {
    return {
      error: {
        code: "network",
        message: response?.data?.statusText || "",
      },
      status: response?.data?.status,
    };
  }

  const balanceResponse: ServerResponse<Account> = await response.data.json();
  if (balanceResponse.error) {
    return {
      error: balanceResponse.error,
    };
  }

  return {
    data: balanceResponse.data,
  };
};

export const useAccount = (enable?: boolean) => {
  const { data, refetch, isLoading, isFetched } = useQuery({
    queryKey: ["Account"],
    queryFn: requestAccountDetails,
    retry: 1,
    enabled: enable,
  });

  return { account: data, isLoading: isLoading, isFetched: isFetched, refetch };
};
