import { useMutation, useQuery } from "@tanstack/react-query";
import { api, fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";
import { DailyBonus } from "../../../types/task";
import { useMemo } from "react";
import { checkSameDayV2 } from "../../../utils/helper";

export async function getDailyMissions() {
  return await api.get<any>(`/api/v1/login-bonuses`);
}

export default function useGetDailyMissions() {
  return useQuery({
    queryKey: ["DailyMissions"],
    queryFn: () => getDailyMissions(),
  });
}

// V2
export const requestDailyMissions = async (): Promise<
  ServerResponse<DailyBonus[]>
> => {
  const response = await fetchApi("GET", "/login-bonuses");
  if (response.error) {
    throw new Error("network-error");
  }

  const dailyResponse: ServerResponse<DailyBonus[]> =
    await response.data.json();
  return dailyResponse;
};

export const requestCheckDailyMission = async (): Promise<
  ServerResponse<DailyBonus>
> => {
  const response = await fetchApi("POST", "/login-bonuses");
  if (response.error) {
    return {
      error: {
        code: "network",
        message: response.error.message,
      },
    };
  }

  const checkDailyResponse: ServerResponse<DailyBonus> =
    await response.data.json();
  return checkDailyResponse;
};

export const useDailyMissions = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["DailyMissions"],
    queryFn: requestDailyMissions,
    retry: (failureCount, error) => {
      return failureCount < 3 && error.message === "network-error";
    },
  });

  const hasDaily = useMemo(() => {
    if (!data?.data?.length) {
      return true;
    }

    const latestBonus = data.data[0];
    return !checkSameDayV2(latestBonus.timestamp);
  }, [data?.data]);

  return {
    dailyMissions: data?.data ?? [],
    hasDaily,
    isLoading,
    refetch,
  };
};

export const useCheckDailyMission = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: requestCheckDailyMission,
  });

  return {
    checkDailyMission: mutateAsync,
    isPending,
  };
};
