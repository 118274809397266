import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";

import { lazy } from "react";

const TutPage = lazy(() => import("./Pages/Tutorial"));
const VotingPage = lazy(() => import("./Pages/Voting"));

export const tutRoute: RouteObject = {
  path: navPaths.TUT,
  children: [{ path: "", Component: TutPage }],
};

export const votingRoute: RouteObject = {
  path: navPaths.VOTING,
  children: [{ path: "", Component: VotingPage }],
};
