import { Backdrop, CircularProgress } from "@mui/material";
import { Slide, ToastContainer } from "react-toastify";

// import NormalMatchMaking from "../Components/MatchMaking/NormalMatchMaking";
// import FriendMatchMaking from "../Components/MatchMaking/FriendMatchMaking";
import { navPaths } from "../../../constants/navbar.constants";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../../components/common/ModalContainer";
import ConfirmLeaveRoomModal from "../Components/Modal/ConfirmLeaveRoom";
import { useState } from "react";
// import PublicMatchMaking from "../Components/MatchMaking/PublicMatchMaking";
import { useBirdBattle } from "../../../store/useBirdBattle";

const FindMatchScreen = () => {
  const navigate = useNavigate();
  const updateRandomHitExclude = useBirdBattle(
    (state: any) => state.updateRandomHitExclude,
  );
  const updateRoom = useBirdBattle((state: any) => state.updateRoom);
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);

  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleRetreat());
  const [isShowLeaveRoomModal, setIsShowLeaveRoomModal] =
    useState<boolean>(false);

  const handleRetreat = () => {
    updateRandomHitExclude([]);
    updateRoom(null);
    updateMatch(null);
    navigate(navPaths.BATTLE_ONLINE);
  };

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="py-[20px] px-5 bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji"
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-sm bg-[#00000033] absolute z-0 top-0 left-0"></div>
        {/* <NormalMatchMaking /> */}
        {/* <FriendMatchMaking /> */}
        {/* <PublicMatchMaking /> */}
      </div>

      <ModalContainer>
        {isShowLeaveRoomModal && (
          <ConfirmLeaveRoomModal
            handleClose={() => setIsShowLeaveRoomModal(false)}
            handleLeaveRoom={() => navigate(navPaths.BATTLE_HOME)}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default FindMatchScreen;
