import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function claimFirstEgg() {
  return await api.post("/api/v1/give-first-egg");
}

export default function useClaimFirstEgg() {
  return useMutation({
    mutationKey: ["claimFirstEgg"],
    mutationFn: claimFirstEgg,
  });
}
