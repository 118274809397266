import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { lazy } from "react";

const AirdropPage = lazy(()=> import('./Pages/Airdrop'))

export const airdropRoute: RouteObject = {
  path: navPaths.AIR_DROP,
  children: [{ path: "", Component: AirdropPage }],
};
