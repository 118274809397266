export const sleep = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatDateTimeHour = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Day of the month (01-31)
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (01-12)
  const hours = String(date.getHours()).padStart(2, "0"); // Hours (00-23)
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes (00-59)

  return `${day}/${month} ${hours}:${minutes}`;
};

export const formatHourMins = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
};

export const formatMonthYear = (date:string)=> {
  const d = new Date(date);
  return (d.toLocaleString('default', { month: 'short' })) + ',' + d.getFullYear()
}
