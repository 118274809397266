export const themeValue = {
  default: "default",
  halloween: "halloween",
};

export const themes = [
  {
    type: themeValue.default,
    img: "/images/trees/6.png",
    color: { border: "#9EDF44", bg_1: "#F7FFEB", bg_2: "#518B00" },
  },
  {
    type: themeValue.halloween,
    img: "/images/trees/halloween.png",
    color: { border: "#C8C8C8", bg_1: "#FF8E4F", bg_2: "#511D00" },
  },
];
